import device from 'reusables/screen'
import styled from 'styled-components'
import {ReactComponent as NotFoundSVG} from 'assets/other/not-found.svg'

export const SVGContainer = styled(NotFoundSVG)`
    width: 80%;
    height: 100%;
    & #group > #text {
        fill:  ${props => props.theme.textPrimary };
    }
`