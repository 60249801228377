export const LightTheme = {
    fontFamily: 'DM Sans',
    bgPrimary: '#f0f5f8',       // White light
    bgSecondary : '#EEEEEE',    // Grayer than white
    bgThird: '#f7f8f7',
    textPrimary: "#181818",     // Github gray-blue text color
    textSecondary: "#767676",   // Github dark-green
    hoverColor: '#08a280',      // Theme green,  
    antiHoverColor: '#e96132',  // Theme red
    btnBackground: '#181818',
    btnText: '#EEEEEE',         
    Secondary: '#DEF2F1',
}

export const DarkTheme = {
    fontFamily: 'DM Sans',
    textPrimary: "white",     // Github gray-blue text color
    textSecondary: "#337732",   // Github dark-green
    bgPrimary: '#161614',       // Github gray dark
    bgSecondary: '#404040',     // Github gray lighter
    btnBackground: '#FEFFFF',
    btnText: '#23272e',
    hoverColor: '#e96132',      // Theme red
    antiHoverColor: '#08a280',  // Theme green
}