import React from 'react'
import {WorkspaceSVG, 
    AElement, 
    ATextTitle,
    AButtonStart,
    ATextDescription
} from './style-elements'

import { SectionLayout } from 'reusables/Layout';
import { RowContainer } from 'reusables/style-elements'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

function PitchSection() {
    return (
        <SectionLayout primary={true}>
            <RowContainer padBottom={"1rem"} applyBorder={true} gap={"2rem"}>
                <AElement data-aos-offset="0" data-aos="fade-left" data-aos-duration="700">
                    <ATextTitle>We develop custom AI components, tailored to your idea.</ATextTitle>
                    <ATextDescription>Leveraging advanced AI technologies in easy-to-use tools, custom components to support your strategy definition and planning: we help you in always making the best decision!
                    </ATextDescription>
                    <AButtonStart href="https://form.jotform.com/222765738800360">Let's connect!<ArrowCircleRightIcon fontSize='large'/></AButtonStart>
                </AElement>
                <AElement data-aos-offset="0" data-aos="fade-right" data-aos-duration="700">
                    <WorkspaceSVG width={500} height={500}/>
                </AElement>
            </RowContainer>
        </SectionLayout>
    )
}

export default PitchSection
