
const CLIENTS = [
    require('assets/clients/datamask.png'),
    require('assets/clients/mynystry.png'),
    require('assets/clients/odeen.png'),
    require('assets/clients/petaland.png'),
    require('assets/clients/repeato.png'),
    require('assets/clients/rydoo.png'),
]
   
export default CLIENTS ;
