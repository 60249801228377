import { useQuery } from 'react-query'
import React, { useState, useEffect } from 'react'
import HeroSection from 'components/hero'
import PitchSection from 'components/intro'
import RoadMapSection from "components/roadmap";
import ServicesSection from 'components/services'
import TechStackSection from "components/techstack"
import {PortfolioSection, ClientsSection} from "components/collaborations";
import Pseudodiv from 'reusables/Pseudodiv'


// Import data structures
import { motion } from 'framer-motion/dist/framer-motion';
import { LoadingLogo } from 'reusables/LoadingAnim';

import {
  REVIEWS,
  ROADMAP,
  SERVICES,
  TECHSTACK,
  PROJECTS,
  PSEUDOELEMENTS,
  CLIENTS
} from 'data'

function Home(){
  const [techstack, setTechStack] = useState([])
  const [services, setServices] = useState([])
  const [rmap, setRoadmap] = useState([])
  const [projects, setProjects] = useState([])
  const [clients, setClients] = useState([])

  const { data: uiItems, isLoading: isLoadingAssets } = useQuery(["uiAssets"], 
    () => {return {
      "techs": TECHSTACK,
      "services": SERVICES,
      "roadmap": ROADMAP,
      "projects": PROJECTS,
      "clients": CLIENTS
    }}
  );

  const { data: ratingsData, isLoading: isLoadingReviews } = useQuery(["reviews"], 
    () => {
      return {
        "reviews": REVIEWS,
    }}
);

useEffect(() => {
  if (!isLoadingAssets) {
    setTechStack(uiItems.techs)
    setServices(uiItems.services)
    setRoadmap(uiItems.roadmap)
    setProjects(uiItems.projects)
    setClients(uiItems.clients)
  }
}, [uiItems, ratingsData])

  return(
    <motion.div>
        <HeroSection/>
        <Pseudodiv elements={PSEUDOELEMENTS}/>
        <PitchSection/>
        {!isLoadingAssets && !isLoadingReviews ? 
          <React.Fragment>
            <ServicesSection name="services" data={SERVICES}/>
            <RoadMapSection name="roadmap"  data={ROADMAP}/>
            <ClientsSection name="clients" data={CLIENTS}/>
            <TechStackSection name="techstack" data={TECHSTACK}/>
            <PortfolioSection name="portfolio" data={PROJECTS}/>
          </React.Fragment>
        : <LoadingLogo/>
        }
    </motion.div>
    )
}
export default Home;