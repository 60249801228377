import React, { useState } from 'react';
import Navbar from 'components/navbar';
import Sidebar from 'components/sidebar';

const NavigationBars = ({themeCallback}) => {
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => {
        setIsOpen(!isOpen)
    }
    return (
        <React.Fragment>
            <Navbar themeCallback={themeCallback} toggle={toggle}/>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
        </React.Fragment>
    )
}

export default NavigationBars;