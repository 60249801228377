// Animation on scroll
import AOS from 'aos';
import 'aos/dist/aos.css';

import React, {useState, useEffect} from 'react'
import { Routes, Route, useLocation} from 'react-router-dom';

// Custom Components

import FooterSection from "components/footer";
import ScrollButton from 'utils/scrollToTop';

// Theme
import { ThemeProvider } from "styled-components";
import { LightTheme, DarkTheme } from "utils/theme";

// Pages
import Home from 'Pages/Home'
import PageNotFound from "Pages/NotFound";
import AboutUsPage from "Pages/AboutUs"
import NavigationBars from "Pages/Navigation"
import BlogPage from "Pages/Blog"
import ProjectsPage from "Pages/Projects"
import ReviewsPage from "Pages/Reviews"

// Data collections
import { SocialsData } from "reusables/Socials";

// Framer motion for page transitions
import {AnimatePresence} from 'framer-motion/dist/framer-motion'

// Env vars
require('dotenv').config()

function App() {
  AOS.init()
  AOS.refresh()

  // Theme callback
  const [theme, setTheme] = useState('light')
  const toggleTheme = () => {
    setTheme((curr) => (curr === 'light' ? 'dark' : 'light'))
  }

  return (
    <ThemeProvider theme={theme === 'light' ? LightTheme : DarkTheme}>
        <NavigationBars themeCallback={toggleTheme}/>
        <AnimatedRoutes/>
        <FooterSection socialsData={SocialsData}/>
    </ThemeProvider>
  );
}

function AnimatedRoutes() {
  // Animating page transitions
  const location = useLocation();
  
  useEffect(() => {
    window.scrollTo(0,0)
  }, [location]);

  return (
      <AnimatePresence>
        <Routes>
              <Route path="*" element={<PageNotFound/>} />
              <Route path="/" element={<Home />} />
              <Route path="/aboutus" element={<AboutUsPage/>} />
              <Route path="/projects/:id" element={<BlogPage/>} />
              <Route path="/projects" element={<ProjectsPage/>} />
              <Route path="/reviews" element={<ReviewsPage/>} />
        </Routes>
        <ScrollButton/>
      </AnimatePresence>
  )
}
export default App;