import React from 'react'

// Roadmap
import { ReactComponent as RMExplore } from 'assets/roadmap/explore.svg'
import { ReactComponent as RMDelivery } from 'assets/roadmap/delivery.svg'
import { ReactComponent as RMVP } from 'assets/roadmap/mvp.svg'
import { ReactComponent as RMChat } from 'assets/roadmap/chat.svg'

// Services

const SVG_DICT = {
    'roadmap': {
        'explore' : <RMExplore/>,
        'delivery': <RMDelivery/>,
        'mvp': <RMVP/>,
        'chat': <RMChat/>
    }   
}

export {
   SVG_DICT
}