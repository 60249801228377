const TECHSTACK = [{
  "name": "Python",
  "img_url": require("assets/tech_stack/python.svg"),
  "doc_link": "https://docs.python.org/3/"
},{
  "name": "FastAPI",
  "img_url": require("assets/tech_stack/fastapi.svg"),
  "doc_link": "https://fastapi.tiangolo.com/"
},{
  "name": "Matplotlib",
  "img_url": require("assets/tech_stack/matplotlib.svg"),
  "doc_link": "https://matplotlib.org/stable/index.html"
},{
  "name": "Seaborn",
  "img_url": require("assets/tech_stack/seaborn.svg"),
  "doc_link": "https://seaborn.pydata.org/"
},{
  "name": "PyTorch",
  "img_url": require("assets/tech_stack/pytorch.svg"),
  "doc_link": "https://pytorch.org/docs/stable/index.html"
},{
  "name": "Keras",
  "img_url": require("assets/tech_stack/keras.svg"),
  "doc_link": "https://keras.io/"
},{
  "name": "Tensorflow 2",
  "img_url": require("assets/tech_stack/tensorflow.svg"),
  "doc_link": "https://www.tensorflow.org/api_docs"
},{
  "name": "OpenCV",
  "img_url": require("assets/tech_stack/opencv.svg"),
  "doc_link": "https://docs.opencv.org/4.x/"
},{
  "name": "React",
  "img_url": require("assets/tech_stack/react.svg"),
  "doc_link": "https://reactjs.org/docs/getting-started.html"
},{
  "name": "Postman",
  "img_url": require("assets/tech_stack/postman.svg"),
  "doc_link": "https://learning.postman.com/docs/getting-started/introduction/"
},{
  "name": "Docker",
  "img_url": require("assets/tech_stack/docker.svg"),
  "doc_link": "https://docs.docker.com/"
},{
  "name": "Google Cloud",
  "img_url": require("assets/tech_stack/googlecloud.svg"),
  "doc_link": "https://cloud.google.com/docs"
},{
  "name": "AWS",
  "img_url": require("assets/tech_stack/aws.png"),
  "doc_link": "https://docs.aws.amazon.com/"
},{
  "name": "Scikit",
  "img_url": require("assets/tech_stack/scikit.svg"),
  "doc_link": "https://scikit-learn.org/"
},{
  "name": "Github",
  "img_url": require("assets/tech_stack/github.svg"),
  "doc_link": "https://github.com"
},{
  "name": "Polyaxon",
  "img_url": require("assets/tech_stack/polyaxon.svg"),
  "doc_link": "https://polyaxon.com/docs/"
},{
  "name": "Grafana",
  "img_url": require("assets/tech_stack/grafana.svg"),
  "doc_link": "https://grafana.com/docs/"
},{
  "name": "Prometheus",
  "img_url": require("assets/tech_stack/prometheus.svg"),
  "doc_link": "https://prometheus.io/docs/introduction/overview/"
},{
  "name": "Jupyter",
  "img_url": require("assets/tech_stack/jupyter.svg"),
  "doc_link": "https://jupyter-notebook.readthedocs.io/en/stable/"
},{
  "name": "ML Flow",
  "img_url": require("assets/tech_stack/mlflow.png"),
  "doc_link": "https://www.mlflow.org/docs/latest/index.html"
}]

export default TECHSTACK;