// Define styled elements to be used in HeroSection 
// Date: 04.05.2022
// Author: Joywalker

import styled from 'styled-components'
import {Link as LinkS} from 'react-scroll'

// Base div container
export const HSContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: 1;
`

// Middle container
export const HSContent = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 3;
    position: absolute;
    align-items: center;
`


export const HSBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw !important;
    height: 100vh !important;
    overflow: hidden;
`

// Background element
export const HSBackgroundVideo = styled.video`
    width: 100%;
    height: 100%;
    max-width: 100vw;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34

    :-webkit-media-controls-start-playback-button {
        display: none!important;
        -webkit-appearance: none;
      }
`

// Middle title
export const HSCenterTitle = styled.h1`
    font-family: ${props => props.theme.fontFamily};
    font-size: 52px;
    margin: 0.5rem 0;
    color: white;
    text-align: center;

    span {
        color: #e96132;
    }
`

// Middle description
export const HSCenterDescription = styled.p`
    color: white;
    font-family: ${props => props.theme.fontFamily};
    font-size: ${({fsize}) => (fsize ? `${fsize}rem` : '20px')};
    margin: 0 auto;
    font-weight: lighter;
    width: 80%;
    text-align: center;
`

// Hero Button
export const HSStartButton = styled(LinkS)`
    margin: 0.5rem auto;
    width: 150px;
    height: 40px;
    line-height: 40px;
    display: block;
    border-radius: 150px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    color: white;
    border: 0;
    background: white;
    color: black;

    &:hover {
        cursor: pointer;
        transform: scale(1.02);
        transition: 0.01s all ease-in;
        background: ${props => props.theme.antiHoverColor};
        color: white;
    }
`