import React, { useState, useEffect } from "react";
import { RElement, ReviewsGrid } from "./style-elements";

// Import reusable components
import Ratings from "./ratings";
import { Modal } from "reusables/Modal";
import { RowContainer } from "reusables/style-elements";

import { FaQuoteRight, FaQuoteLeft } from "react-icons/fa";
import {
  ReviewItem,
  StyledRating,
  RatingRow,
  RatingButton,
  ModalReviewsContainer,
} from "./style-elements";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { PageLayout } from "reusables/Layout";
import { REVIEWS } from "../../data";


function ReviewsPage() {
  const [show, setShow] = useState(false);
  const [isValid, setValid] = useState(false);
  const [modal, setModal] = useState(null)
  const [renderElements, setRenderElements] = useState(null);

  const calculateRating = () => {
    let ratings_mapping = {
      5: {
        cnt: 0,
        color: "#01A25B",
      },
      4: {
        cnt: 0,
        color: "#B7EA83",
      },
      3: {
        cnt: 0,
        color: "#F6D757",
      },
      2: {
        cnt: 0,
        color: "#F17A55",
      },
      1: {
        cnt: 0,
        color: "#F17A55",
      },
    };

    REVIEWS.map((rev) => {
      return (ratings_mapping[rev.rating]["cnt"] += 1);
    });
    return ratings_mapping;
  };

  const toggleReviewsModal = () => {
    setShow(true);
    const reviewsComponentChildren = (
      <ModalReviewsContainer>
        <RElement>
         <Ratings
                total={renderElements.length}
                ratings={calculateRating()}
          />
          </RElement>
      </ModalReviewsContainer>
    );
    const modal_component = (
      <Modal
        show={show}
        handleClose={closeModal}
        children={reviewsComponentChildren}
      />
    );
    setModal(modal_component);
  };

  const closeModal = () => {
    setShow(false);
  };

  useEffect(() => {
    const reviewsDataItems = REVIEWS.map((rev, i) => {
      return (
        <ReviewItem key={i}>
          <h4>{rev.job_title}</h4>
          <h5>{rev.author}</h5>
          <StyledRating
            precision={0.1}
            readOnly
            value={rev.rating}
            icon={<StarIcon fontSize="inherit" />}
            emptyIcon={<StarBorderIcon fontSize="inherit" />}
          />
          <p>
            <span>
              <FaQuoteLeft
                fontSize={"12px"}
                style={{
                  marginRight: "1em",
                  paddingTop: "1em",
                }}
              />
            </span>
            {rev.review}
            <span>
              <FaQuoteRight
                fontSize={"12px"}
                style={{ marginLeft: "1em", paddingTop: "1em" }}
              />
            </span>
          </p>
        </ReviewItem>
      );
    });
    if (reviewsDataItems.length > 0) {
      setRenderElements(reviewsDataItems);
      setValid(true);
    }
  }, []);

  return (
    <PageLayout primary={false}>
      {isValid ? (
        <React.Fragment>
              <ReviewsGrid>
                {renderElements}
              </ReviewsGrid>
        </React.Fragment>
      ) : null}
      {modal ? modal : null}
    </PageLayout>
  );
}

export default ReviewsPage;
