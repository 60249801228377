const REVIEWS = [{
  "author": "Nick D.",
  "rating": 5,
  "job_title": "Python Developer needed for creating a random art generator",
  "review": "This guy is an amazing python developer. He can literally do anything. I have used him to build out a dozen programs to automate functions in my company and on projects I'm working on and he is a literal wizard. If you want a total pro who speaks perfect english and can help you with anything... this is the man for the job. Highly recommended and I will continue using him for everything."
},{
  "author": "Ben Falk",
  "job_title": "Identify and parse the clock on a sports broadcast video using OCR/OpenCV",
  "rating": 5,
  "review": "Alex was great - he did successful work in a timely fashion, trying multiple different options until he found a good solution. And he was a good communicator."
},{
  "author": "Kim F.",
  "job_title": "Tensorflow GPU set up",
  "rating": 5,
  "review": "Alex delivered excellent work on resolving the issues that I had with TensorFlow. Alex is very kind, comprehensive, and professional. Most importantly, He is excellent in Tensorflow. He quickly resolves the problems and would explain each detail so that I could completely understand the process. He was always prepared and adhered to the schedule. Working with Alex was the best experience that I have had on Upwork, and I am willing to work with him again for Tensorflow related works. I strongly recommend him!!"
},{
  "author": "Nick D.",
  "job_title": "Python Developer for NFT program",
  "rating": 5,
  "review": "This guy is a great programmer! He did exactly what I needed and was so on top of the ball. His communication was great and he speaks perfect English. Will absolutely use him for all of my Python needs."
},{
  "author": "Mike",
  "job_title": "QR code reader from images",
  "rating": 5,
  "review": "Alexandru is very professional and dedicated to completing the project successfully."
},{
  "author": "Nick D.",
  "job_title": "Python Developer needed for creating automations.",
  "rating": 5,
  "review": "No feedback given."
},{
  "author": "Vidit K.",
  "job_title": "Improving or Modifying Existing Face Shape Classification Model.",
  "rating": 4,
  "review": "He was thorough in his work, read the documentation well and bid accordingly. He also was prepared with things to do well before he was appointed, and wasted no time in building the project!"
},
{
  "author": "Martin D.",
  "job_title": "Docker for Signature Recognition",
  "rating": 5,
  "review": "Alexandru understood the assignment perfectly. Very nice to work with, good communication and the result is also as desired. Alexandru also did work after the job to improve the model in speed and performance."
},
{
  "author": "Stephan P.",
  "job_title": "Finding elements in screens using computer vision",
  "rating": 5,
  "review": "No feedback given."
}]

export default REVIEWS;

