import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'
import device from 'reusables/screen'
import {motion} from 'framer-motion/dist/framer-motion'


export const ThemeSwitchContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: min-content;
    height: 100%;
`

export const Nav = styled.nav`
    width: 100%;
    height: 60px;
    margin-top: -60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 100;
    background:rgba(0,0,0,.7);
    backdrop-filter: blur(25px);
    margin-left: auto;
    margin-right: auto;
`

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    height: 60px;
    z-index: 1;
    width: 90%;
    max-width: 1100px;
    padding: 10px 24px;
    gap: 2rem;
`

export const NavLogo = styled(LinkR)`
    color: ${props => props.theme.hoverColor};
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-decoration: None;
    margin-left: 0;
    height: 100%;
`

export const NavImage = styled.img`
    display: block;
    margin: 0 auto;
    object-fit: contain;
    height: 50px;
    width: 50px;

    @media screen and ${device.mobileLmax} {
        display: none;
    }
`
export const NavLogoText = styled.h1`
    display: flex;
    color: ${props => props.theme.hoverColor} !important;
    font-family: Detacher;
    font-size: 15px;
    font-weight: 100;
    text-align:center;
    width: min-content;
    white-space: break-space;
    margin: 0 auto;
    letter-spacing: 0.1rem;
    text-decoration: None;
    padding: 0;

    span {
        color: ${props => props.theme.antiHoverColor} !important;
    }

   
    
`
export const NavLinksContainer = styled.ul`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;
    text-align: center;
    margin: 0 auto;
    padding: 0;
    @media screen and ${device.tabletmax} {
        display: none;
    }
`
export const NavRLink = styled.a`
    font-family: ${props => props.theme.fontFamily};
    font-size: 1rem;
    display: flex;
    align-items: center;
    text-decoration: None;
    padding: 0 1rem;
    color: #fff;
    height: 100%;
    cursor: pointer;

    &:hover {
        transition: 0.2s all ease-in-out;
        color: ${props => props.theme.antiHoverColor};
    }
`
export const NavLink = styled(LinkS)`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: None;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-family: ${props => props.theme.fontFamily};
    font-size: 1rem;

    &:hover {
        transition: 0.2s all ease-in-out;
        color: ${props => props.theme.antiHoverColor};
    }
    &.active {
        border-bottom: 4px solid ${props => props.theme.antiHoverColor};
    }
`
export const NavItem = styled.li`
    height: 60px;
`
export const MobileIcon = styled.div`
    position: relative;
    color: white;
    display: none;
    z-index: 99;

    @media screen and ${device.tabletmax} {
        display: flex;
        height: 100%;
        margin: auto 0;
        font-size: 1.8rem;
        color: white;
        cursor: pointer;
    }
`

export const NavButton = styled(motion.button)`
    display: block;
    color: white;
    background: ${props => props.theme.hoverColor};
    border: 0;
    font-size: 15px;
    font-family: ${props => props.theme.fontFamily};
    height: 40px;
    width: 100px;
    padding: 3px 5px;
`