import React, { Component, useState, Suspense} from "react";
import { SVG_DICT } from 'assets/static_loaded'
import {RoadMapRow, RoadMapItemLeft, 
  RoadMapItemRight, RoadMapRowContainer, 
  RoadMapItemTitle, RoadMapRowContents} from './style-elements'
import { Modal, ModalImage, ModalDescription, ModalItems} from 'reusables/Modal'
import { Title } from "reusables/style-elements";
import {SectionLayout} from 'reusables/Layout'
import "./animation-styles.css"


function RoadMapSection(props) {
  
  const [show, setShow] = useState(false)
  const [modal, setModal] = useState(null)

  function showModal(event) {
    const idx = event.currentTarget.id
    const elem_data = props.data[idx]
    const modal_children = (
      <>
        <ModalItems>
          <ModalImage>
              {load_svg(elem_data.svg_id)}
          </ModalImage>
          <ModalDescription>
              <Title>{elem_data.desc.title}</Title>
              <p>{elem_data.desc.info}</p>
              <ul>{elem_data.desc.items.map((item, idx)=> {
                  return (
                  <li key={idx}>
                    {item.link !== "" ? <a href={item.link}>{item.topic}</a> :  <>{item.topic}</>}
                  </li>)
              })}</ul>
          </ModalDescription>
        </ModalItems>
      </>
    )
    const modal_component = <Modal padding={"1.5rem"} show={show} handleClose={hideModal} children={modal_children}/>
    
    setShow(true)
    setModal(modal_component)
  }

  function hideModal() {
    setShow(false)
  }

  function load_svg(path) {
    return SVG_DICT['roadmap'][path]
  }


  return (
    <SectionLayout name="roadmap" primary={true}>
        <Title>Our Process</Title>
        <RoadMapRowContainer>
          {props.data.map((elem, idx) => {
            if (idx % 2 === 1) {
              return (
              <RoadMapRow key={idx}  data-aos='fade-up' data-aos-duration='600'>
                <RoadMapItemLeft  id={idx} onClick={showModal}>
                  <RoadMapRowContents>
                        {load_svg(elem.svg_id)}
                    <RoadMapItemTitle>{elem.title}</RoadMapItemTitle>
                  </RoadMapRowContents>
                </RoadMapItemLeft>
              </RoadMapRow>
              )
            } else {
              return (
                <RoadMapRow key={idx} data-aos='fade-down' data-aos-duration='600' >
                  <RoadMapItemRight id={idx} onClick={showModal} > 
                    <RoadMapRowContents>
                        {load_svg(elem.svg_id)}
                      <RoadMapItemTitle>{elem.title}</RoadMapItemTitle>
                    </RoadMapRowContents>
                  </RoadMapItemRight>
                </RoadMapRow>
                )
            }
          })}
        </RoadMapRowContainer>
      {show ? modal: null} 
    </SectionLayout>
  )
}

export default RoadMapSection