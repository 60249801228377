const size = {
    mobileS: "320",
    mobileM: "375",
    mobileL: "470",
    tablet: "768",
    laptop: "1024",
    laptopL: "1440",
    desktop: "2560",
  };
  
  const device = {
    mobileSmin: `(min-width: ${size.mobileS}px)`,
    mobileSmax: `(max-width: ${size.mobileS}px)`,
    mobileMmin: `(min-width: ${size.mobileM}px)`,
    mobileMmax: `(max-width: ${size.mobileM}px)`,
    mobileLmin: `(min-width: ${size.mobileL}px)`,
    mobileLmax: `(max-width: ${size.mobileL}px)`,
    tabletmin: `(min-width: ${size.tablet}px)`,
    tabletmax: `(max-width: ${size.tablet}px)`,
    laptopmin: `(min-width: ${size.laptop}px)`,
    laptopmax: `(max-width: ${size.laptop}px)`,
    laptopLmin: `(min-width: ${size.laptopL}px)`,
    laptopLmax: `(max-width: ${size.laptopL}px)`,
    desktopmin: `(min-width: ${size.desktop}px)`,
    desktopmax: `(max-width: ${size.desktop}px)`,
  };


  export default device;