import device from './screen'
import styled from 'styled-components'

export const Title = styled.h1`
    font-family: ${props => props.theme.fontFamily};
    font-size: ${({fsize}) => (fsize ? `${fsize}rem` : '42px')};
    margin: ${props => props.margin ? props.margin : '1rem auto'};
    font-weight: 900;
    width:  ${props => props.width ? props.width : '100%'};
    color: ${props => props.theme.textPrimary};
    text-align: ${props => props.talign ? props.talign : "center"};
 
    @media screen and ${device.mobileLmax} {
        text-align: center;
    }    
`

export const RowContainer = styled.div`
    display: flex;
    width 100%;
    max-width: 1100px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    border-bottom: ${props => props.applyBorder === true ? "1px solid" : "0"} ${props => props.theme.textPrimary};
    padding-bottom: ${props => props.padBottom};
    gap: ${props => props.gap};

    @media screen and ${device.tabletmax} {
        flex-direction: column;
        align-items: center;
    }
`