import styled from 'styled-components'
import screen from '../../reusables/screen'

export const PWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 0.5rem;
    padding: 10px 0px;

    @media screen and ${screen.tabletmax} {
        grid-template-columns: 1fr 1fr; 
    }

    @media screen and ${screen.mobileLmax} {
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`