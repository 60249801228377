import styled from 'styled-components'
import device from 'reusables/screen'

export const RoadMapContainer = styled.div`
  height: 100%;
  margin: 0px auto;
  background: ${props => props.theme.Primary };
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;



export const RoadMapItemTitle = styled.h1`
  width: 100%;
  padding: 0;
  margin: 0 auto;
  text-align: center;
  font-weight: 300;
  color: ${props => props.theme.textPrimary};
  font-size: 1.5rem;
  font-family: ${props => props.theme.fontFamily};
`

export const RoadMapRowContents = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  margin: 0 auto;

  svg {
    width: 224px;
    height: auto;
  }

  
`
export const RoadMapItemRight = styled.div`
    border-left: 3px solid ${props => props.theme.textPrimary};
    position: relative;
    margin-left: auto;
    margin-right: 0;
    width: 50%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.hoverColor}15;
    
    &:hover {
      cursor: pointer;
      transition: 0.2s all ease-in-out;
      border-left: 3px solid ${props => props.theme.hoverColor};
      background: ${props => props.theme.hoverColor}60;
      border-radius: 0 10px 10px 0;

    }
    
    ::before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        width: 18px;
        height: 18px;
        border-radius: 50px;
        top: 50%;
        left: -10px;
        background: ${props => props.theme.hoverColor };
    }

`

export const RoadMapItemLeft = styled.div`
  border-right: 3px solid ${props => props.theme.textPrimary};
  position: relative;
  margin-right: auto;
  margin-left: -3px;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.hoverColor}15;

  
  &:hover {
    cursor: pointer;
    transition: 0.2s all ease-in-out;
    border-right: 3px solid ${props => props.theme.hoverColor};
    background: ${props => props.theme.hoverColor}60;
    border-radius: 10px 0 0 10px;

  }
  ::after {
    right: -10px;
    content: "";
    box-sizing: border-box;
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50px;
    top: 50%;
    background: ${props => props.theme.hoverColor };
`;


export const RoadMapRowContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media screen and ${device.mobileLmax} {
    padding: 0;
    gap: 2rem;
 }
`;

export const RoadMapImage = styled.svg`
  display: block;
  width: 300px;
  object-fit: contain;
  margin: 0 auto;
  
  @media screen and ${device.tabletmax} {
    width: 200px;
    padding: 0;
 }
`

export const RoadMapRow = styled.div`
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 1rem;
  max-width: 1200px;

  @media screen and ${device.tabletmax} {
    width: 100%;
    padding: 0;
  }

  @media screen and ${device.mobileLmax} {
    flex-direction: column;

    & ${RoadMapItemLeft}, ${RoadMapItemRight} {
      width: 100%;
      margin: 0;
      border: 0;
      padding: 0;

      ::before, ::after {
        content: none;
        margin: 0 auto;
      }

`;
