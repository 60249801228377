import styled from 'styled-components'
import ReactCardFlip from 'react-card-flip';
import React, { useState, useCallback } from 'react'
import {MiscItems, CustomIcon} from 'utils/library'

const PImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    img {
        height: 150px;
        width: 200px;
        float: left;
        display: block;
        object-fit: contain;
    }
    
`

const PContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 0.5rem;
    margin: 0px auto;

`


const PTitleH2 = styled.h2`
    text-align: center;
    font-family: ${props => props.theme.fontFamily};
    font-size: 1.5rem;
    color: ${props => props.theme.textPrimary};
    margin-bottom: 30px;
    padding: 0;
`

const PItem = styled.div`
    display: flex;
    background: ${props => props.theme.bgSecondary }90;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 10px;
    height: 350px;
    min-width: 260px;
    max-width: 400px;
    padding: 10px;
    box-shadow: 0 5px 5px rgba(0,0,0, 0.2);
    // transition: 0.2s all ease-in-out;
    text-decoration: none;
    &:hover {
        transform: scale(1.03);
        transition: 0.2s all ease-in-out;
        cursor: pointer;

        & ${PTitleH2} {
            color: ${props => props.theme.hoverColor};
        }
    }
`

const PDescription = styled.ul`
    display: inline-block;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0 auto;

    div {
        display: flex;
        margin: 0.8rem auto;
        flex-direction: row;
        justify-content: space-between;

        svg {
            margin-right: 0.5rem;
            margin-left: auto;
        }

    }

    p {
        display: list-item;
        list-style: none;
        max-width: 230px;
        margin: 0 auto;
        text-align: center;
        color:  ${props => props.theme.textPrimary};
        font-family: ${props => props.theme.fontFamily};
    }
`

const PInfo = styled.p`
    text-align: center;
    font-size: 12px;
    font-family:${props => props.theme.fontFamily};
`

function FlippingCard({title, image, metadata, idx}) {
    const [isFlipped, changeFlip] = useState(false);
    const handleClick = useCallback((event) => {
        changeFlip(!isFlipped)
    })

  return (
    <ReactCardFlip
        isFlipped={isFlipped}
        key={idx}
        flipDirection="horizontal">
        <PItem key="front" onClick={handleClick}>
            <PImage>
                <img src={image}/>
            </PImage>
            <PTitleH2>{title}</PTitleH2>
        </PItem>
        <PItem key="back" onClick={handleClick}>
            <PTitleH2>{title}</PTitleH2>
            <PDescription>
                <p>{metadata}</p>
            </PDescription>
        </PItem>
    </ReactCardFlip>
  )
}

export default FlippingCard