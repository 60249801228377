// This component would represent a div that connects two sections
// Check div between sections : https://polyaxon.com/?utm_source=github&utm_medium=core-logo

import React from 'react'
import styled from 'styled-components'
import {CustomIcon} from 'utils/library'

import device from './screen'

const BaseContainer = styled.div`
    position: absolute;
    z-index: 99;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: -35px auto 0 auto;
    width: 100%;
    height: 70px;
    backdrop-filter: blur(3px);

    @media screen and ${device.tabletmax} {
        display: none;
    }
`
const Container = styled.div`
    position: relative;
    z-index: 99;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1100px;
    height: 140px !important;
    background: white;
    border-radius: 15px;
    -webkit-box-shadow: 0 0 5px rgb(0 0 0 / 2%), 0 5px 22px -6px rgb(0 0 0 / 10%), 0 -12px 35px -19px rgb(0 0 0 / 20%);
    box-shadow: 0 0 5px rgb(0 0 0 / 2%), 0 5px 22px -6px rgb(0 0 0 / 10%), 0 -12px 35px -19px rgb(0 0 0 / 20%);
    -webkit-transition: all .8s ease;
    transition: all .8s ease;

    
    
`

const Item = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;

    width: auto;
    height: 140px;
    margin: 0 auto;

    p {
        font-family: 'DN Sans', sans-serif;
        color: #738a94;
        font-size: 12px;
        margin: 0;
        padding: 0;
        line-height: 30px;
        text-align: center;
    }
`

function Pseudodiv(props) {
  const elements = props.elements !== undefined ? props.elements : null;
  return (
    <BaseContainer>
        <Container data-aos='fade-up' data-aos-duration='1000'>
            {elements.map((elem, idx) => {
                return (
                    <Item key={idx}>
                        <CustomIcon margin={"0 auto"}icon={elem.icon} color="black" size={"6x"} spin={elem.spin}/>
                        <p>{elem.text}</p>
                    </Item>
                )
            })}
        </Container>
    </BaseContainer>
  )
}

export default Pseudodiv;