import React from 'react'
import {
    TSItem,
    TSLogo,
    TSElementsContainer,
    TSColumnContainer
} from './style-elements'

import {SectionLayout} from 'reusables/Layout'
import { Title } from "reusables/style-elements"
import {RowContainer} from 'reusables/style-elements'


function TechStackSection({name, data}) {
  return (
    <SectionLayout name={name} primary={false}>
        <RowContainer>
            <TSColumnContainer id="title">
                <Title>
                    Technologies we use ...
                </Title>
            </TSColumnContainer>
            <TSColumnContainer>
                <TSElementsContainer>
                {data.map((elem, idx) => {
                    return (
                    <TSItem 
                    data-aos='fade-left' data-aos-duration='600'
                    key={idx} href={elem.doc_link}>
                        <TSLogo src={elem.img_url}/>
                    </TSItem>
                    )
                })}
                </TSElementsContainer>
            </TSColumnContainer>
        </RowContainer>
    </SectionLayout>
  )
}

export default TechStackSection;