const ROADMAP = [
  {
    "svg_id": "explore",
    "title": "Research & Explore",
    "desc": {
      "title": "Research & Explore",
      "items": [
        {
          "topic": "",
          "link": ""
        }
      ],
      "info": "Maybe you have seen some interesting AI projects, or you have heard discussions about Machine Learning and you'd like incorporate AI to your own ideas but don't know where to start? In the next steps, we'll explain the process we take!",
    }
  },
  {
    "svg_id": "chat",
    "title": "Discussion & Planning",
    "desc": {
      "title": "Discussion & Planning",
      "info": "We will provide you with the best guidelines on how to customize and adapt an AI based component to the specific use case of your business problem. Starting from discussing the impact to requirements and scalability. Feel free to book a consultancy call with us at:",
      "items": [
        {
          "topic": "Book a call",
          "link": "https://form.jotform.com/222765738800360"
        }
      ]
    }
  },
  {
  "svg_id": "mvp",
  "title": "Development & Testing",
  "desc": {
    "title": "Development & Testing",
    "items": [
      {
        "topic": "",
        "link": ""
      }
    ],
    "info": "Once we establish the best approach, we will start planning the development process. In the prototype phase, we will emphasize on building an MVP that can be easily scaled to solve your problem."
  }
},{
  "svg_id": "delivery",
  "title": "Deploy & Maintenance",
  "desc": {
    "title": "Deploy & Maintenance",
    "items": [
      {
        "topic": "",
        "link": ""
      }
    ],
    "info": "With a solution that validates the business problem, we can proceed to integrate it into your current software stack, or deploy it as a standalone service on a cloud provider of your choice."
  }
}]

export default ROADMAP