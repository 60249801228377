import React from 'react'
import {
    SidebarContainer, 
    Icon,
    SidebarWrapper,
    SidebarMenu,
    SideBtn,
    SidebarLink
} from './style-elements'
import {CustomIcon, NavbarIcons} from 'utils/library'

const Sidebar = ({isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CustomIcon s="30px" color={"#fff"} icon={NavbarIcons.signin}/>
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to="services">Services</SidebarLink>
                <SidebarLink to="roadmap">Roadmap</SidebarLink>
                <SidebarLink to="reviews">Reviews</SidebarLink>
                <SidebarLink to="techstack">Technologies</SidebarLink>
            </SidebarMenu>
            <SideBtn>
                <SidebarLink to="/contact">Contact</SidebarLink>
            </SideBtn>
        </SidebarWrapper>
    </SidebarContainer>

  )
}

export default Sidebar