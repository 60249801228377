import {i_linkedin, i_github, i_medium} from 'utils/library'
const ABOUT = {
    "arazvant": [
        {
            link: 'https://linkedin.com/in/arazvant',
            icon: i_linkedin
        },
        {
            link: 'https://github.com/Neural-Leaps-Unlimited',
            icon: i_github
        },
        {
            link: 'https://medium.com/@alexandrurazvant',
            icon: i_medium
        }
        ]
    }

export default ABOUT ;
