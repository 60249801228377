import styled from 'styled-components'
import device from 'reusables/screen'
import Rating from '@mui/material/Rating';
import { motion } from 'framer-motion/dist/framer-motion'


export const RContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 0px auto;
    max-width: 1200px;
    width: 100%;

    @media screen and ${device.tabletmax} {
        flex-direction: column;
        justify-content: center;
    }
`   

export const RElement = styled.div`
    display: flex;
    margin-top: 0;
    margin-bottom: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 25px;
    width: 50% !important;

    @media screen and ${device.tabletmax} {
        flex-direction: column;
        width: 100% !important;
        margin: 0px auto;
    }

`

export const RTitle = styled.h1`
    padding: 0;
    font-weight: 700;
    font-family: ${props => props.theme.fontFamily};
    color: ${props => props.theme.textPrimary};
    font-size: ${props => props.theme.reviews.titleH1Size};
    margin: ${({margin}) => (margin ? `${margin}` : '0')};;
`

export const RatingRow = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.5rem;
    flex-direction: row;    
    padding: 0;

    h3 {
        font-family: ${props => props.theme.fontFamily};
        text-align: center;
        font-size: 12px;
        margin-top: auto;
        margin-bottom: auto;
        padding: 0 8px;
        color: ${props => props.theme.textPrimary};
    }

    @media screen and ${device.mobileLmax} {
        width: 100% !important;
        justify-content: center;
        align-items: center;
        flex-direction: column;    

        h3 {
            padding: 0;
            margin: 0 auto;
        }
    }
`

export const RatingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0;
    margin: 0 auto;
    height: 100%;

    @media screen and ${device.tabletmax} {
        display: none;
    }

    @media screen and ${device.tabletmax} {
        justify-content: center;
        align-item: center;
        padding: 2 rem auto;
    }

`
export const RRating = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
`
export const RRatingBars = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;

   @media screen and ${device.mobileLmax} {
    width: 100% !important;
    justify-content: center;
    align-item: center;
    padding: 2 rem auto;
}
`

export const RRatingBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 1.5em;

    & .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: color: ${props => props.theme.textPrimary};
        color: color: ${props => props.theme.textSecondary};
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        position: absolute;
        z-index: 1;   
    }

    div {
        width: 20rem;
        height: 1.05rem;
        background: #F3F2F9;
        border-radius: 3px;

        span {
            border-radius: 3px;
        }

        &:hover {
            .tooltiptext {
                visibility: visible;
            }
        }
        
    }

    h4 {
        width: 48px;
        font-size: 12px;
        margin: 0 0.5rem;
        color: ${props => props.theme.textPrimary};
        font-weight: bold;
    }

    h5 {
        margin-right: 0.4rem;
        font-size: 12px;
        color: ${props => props.theme.textPrimary};
    }

    @media screen and ${device.tabletmax} {
        div {
            width: 15rem;
        }
        
        h4 {
            width: auto;
        }

        h5 {
            margin: 0px auto;
        }
    }

`

export const RatingButton = styled(motion.button)`
    max-width: 150px;
    min-width: 80px;
    width: 130px;
    margin-top: 1rem;
    height: 2.5rem;
    border: 0;
    background: ${props => props.theme.bgPrimary};
    color: ${props => props.theme.textPrimary};
    font-family: ${props => props.theme.fontFamily};
    font-weight: 400;
`

export const ReviewItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;

    h4 {
        font-family: ${props => props.theme.fontFamily};
        display: flex-box;
        justify-content: center;
        text-align: center;
        margin: 0 auto;
        font-size: 20px;
        color: ${props => props.theme.textPrimary};
        max-width: 320px;
        margin-bottom: 0.2em;
    }

    p {
        font-size: 16px;
        font-family: ${props => props.theme.fontFamily};
        width: 90%;
        font-style: italic;
        color: ${props => props.theme.textPrimary};
        text-align: center;
        margin: 0 auto;

        span {
            color: ${props => props.theme.hoverColor};
        }
    }

    h5 {
        font-family: ${props => props.theme.fontFamily};
        display: flex-box;
        justify-content: center;
        text-align: center;
        margin: 0 auto;
        font-size: 12px;
        color: ${props => props.theme.antiHoverColor};
        max-width: 320px;
        margin-top: 0.2em;
    }
    
    @media screen and ${device.tabletmax} {
        width: 100%;
    }

    @media screen and ${device.mobileLmax} {
        p {
            font-size: 14px;
        }

        h5 {
            font-size: 10px;
        }

        h4 {
            font-size: 18px;
        }
    }
`;

export const StyledRating = styled(Rating)`
    
    & .MuiRating-iconFilled {
        color: ${props => props.theme.hoverColor} !important;
    }
        
    & .MuiRating-iconHover {
        color: ${props => props.theme.hoverColor} !important;
    }

    & .MuiRating-iconDisabled {
        color: ${props => props.theme.hoverColor} !important;
    }
`

export const ModalReviewsContainer = styled.div`
    display: grid;
    padding: 2rem 0;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    width: 100%;
    height: 75vh;
    column-gap: 1rem;
    row-gap: 2rem;
    overflow-y: auto;
    margin: 0 auto;

    @media screen and ${device.mobileLmax} {
        grid-template-columns: repeat(1, 1fr);
    }
`

export const ReviewsGrid = styled.div`
    display: grid;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    width: 100%;
    padding: 2rem;
    max-width: 1100px;

    @media screen and ${device.mobileLmax} {
        padding: 1rem;
        gap: 2rem;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    @media screen and ${device.tabletmax} {
        grid-template-columns: 1fr 1f;
        grid-template-rows: auto;
    }
`