import React from 'react'
import { PageLayout } from 'reusables/Layout';
import { SVGContainer } from './style-elements'

export default function PageNotFound() {
    return (
      <PageLayout primary={true}>
        <SVGContainer/>
      </PageLayout>
    )

}
