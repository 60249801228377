import React from 'react'
import {FContainer, 
    FContainerColumn,
    LogoContainer, 
    FBaseContainer,
    FTitle,
    FColumn,
    FLink} from './style-elements'

import Socials from 'reusables/Socials'
import { NavImage, NavLogoText } from 'components/navbar/style-elements'

// Import footer data
import {FOOTER} from 'data'

function FooterSection(props) {
    return (
        <FBaseContainer>
            <FContainer>
                <FColumn width={"auto"}> 
                        <LogoContainer
                            data-aos="fade-left" 
                            data-aos-duration="500">
                            <NavImage src={FOOTER.logo}/>
                            <NavLogoText>NEURA<span>LEAPS</span></NavLogoText>
                        </LogoContainer>
                        <FTitle 
                        data-aos="fade-up" 
                        data-aos-duration="500"
                        size={12} >© 2022 - NeuraLeaps. All rights reserved.</FTitle>
                        <Socials color={'white'} socialsData={props.socialsData}/>
                    </FColumn>
                    {FOOTER.elements.map((elem, idx) => {
                        return (
                            <FContainerColumn key={idx}>
                                <h4>{elem.headerTitle}</h4>
                                <ul>
                                    {elem.subLinks.map((sublink, idx) => {
                                        return <li key={idx} ><FLink to={sublink.link}>{sublink.title}</FLink></li>
                                    })}
                                </ul>
                            </FContainerColumn>
                        )
                    })}
            </FContainer>
        </FBaseContainer>
    )
}

export default FooterSection;
