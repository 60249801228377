// Setup icons library
import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library, icon, findIconDefinition} from '@fortawesome/fontawesome-svg-core'
import { fab } from "@fortawesome/free-brands-svg-icons"
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
library.add(fas, fab, far)

// Global icon styling
export const CustomIcon = styled(FontAwesomeIcon)`
    display: block;    
    width: ${props => props.s ? props.s : "1.7rem"} !important;
    height: ${props => props.s ? props.s : "1.7rem"} !important;
    color: ${props => props.color ? props.color : props.theme.textPrimary};
    margin: ${props => props.margin ? props.margin : "auto"};
    padding: ${props => props.padding ? props.padding : "0"};
    background: ${props => props.bg ? props.bg : "transparent"};
    border-radius: ${props => props.br ? props.br : "0"};
    overflow: hidden;
    vertical-align: middle;

    :hover {
        color: ${props => props.bhover ? props.theme.hoverColor : "none"};
    }
`

// Export social icons
export const i_facebook = icon(findIconDefinition({ prefix: 'fab', iconName: 'facebook'}))
export const i_github = icon(findIconDefinition({ prefix: 'fab', iconName: 'github' }))
export const i_linkedin = icon(findIconDefinition({ prefix: 'fab', iconName: 'linkedin-in' }))
export const i_medium = icon(findIconDefinition({ prefix: 'fab', iconName: 'medium' }))

// Export pseudodiv icons
export const people_box = icon(findIconDefinition({ prefix: 'fas', iconName: 'people-carry-box' }))
export const bullseye = icon(findIconDefinition({ prefix: 'fas', iconName: 'bullseye' }))
export const lightbulb = icon(findIconDefinition({ prefix: 'fas', iconName: 'lightbulb' }))
export const design = icon(findIconDefinition({ prefix: 'fas', iconName: 'arrows-rotate' }))
export const test = icon(findIconDefinition({ prefix: 'fas', iconName: 'cubes' }))

export const PseudoDivIcons = {
    people_box,
    bullseye,
    lightbulb,
    design,
    test
}

// Register & Login forms icons
export const password_hidden = icon(findIconDefinition({ prefix: 'fas', iconName: 'eye-slash' }))
export const password_see = icon(findIconDefinition({ prefix: 'fas', iconName: 'eye' }))
export const circle_user = icon(findIconDefinition({ prefix: 'fas', iconName: 'circle-user' }))
export const password_key = icon(findIconDefinition({ prefix: 'fas', iconName: 'key' }))
export const email = icon(findIconDefinition({ prefix: 'fas', iconName: 'envelope' }))
export const fullname = icon(findIconDefinition({ prefix: 'fas', iconName: 'user' }))
export const UserAuthIcons = {
    circle_user,
    password_key,
    email,
    fullname,
    password_see,
    password_hidden
}


// Flippable cards icons
export const flip_on = icon(findIconDefinition({ prefix: 'fas', iconName: 'toggle-on' }));
export const flip_off = icon(findIconDefinition({ prefix: 'fas', iconName: 'toggle-off' }));

// Export additional icons
export const i_arrow_r = icon(findIconDefinition({ prefix: 'fas', iconName: 'circle-arrow-right' }));
export const i_arrow_down = icon(findIconDefinition({ prefix: 'fas', iconName: 'circle-arrow-down' }));
export const i_arrow_up = icon(findIconDefinition({ prefix: 'fas', iconName: 'circle-up' }));
export const i_exit = icon(findIconDefinition({ prefix: 'fas', iconName: 'xmark' }));
export const i_heart_f = icon(findIconDefinition({ prefix: 'fas', iconName: 'heart' }));
export const i_heart_o = icon(findIconDefinition({ prefix: 'far', iconName: 'heart' }));
export const i_star_o = icon(findIconDefinition({ prefix: 'far', iconName: 'star' }));
export const i_star_f = icon(findIconDefinition({ prefix: 'fas', iconName: 'star' }));
export const i_question = icon(findIconDefinition({ prefix: 'fas', iconName: 'circle-question' }));

// Navbar icons
export const signin = icon(findIconDefinition({ prefix: 'fas', iconName: 'right-to-bracket' }));
export const signout = icon(findIconDefinition({ prefix: 'fas', iconName: 'right-from-bracket' }));
export const bars = icon(findIconDefinition({ prefix: 'fas', iconName: 'bars' }));
export const NavbarIcons = {
    signin,
    signout,
    bars
}

// Export GPT 3 model config icons
export const ai_model = icon(findIconDefinition({ prefix: 'fas', iconName: 'robot' }));
export const gpt3_temp = icon(findIconDefinition({ prefix: 'fas', iconName: 'temperature-half' }));
export const gpt3_tokens = icon(findIconDefinition({ prefix: 'fas', iconName: 'arrow-up-1-9' }));
export const up_right_arrow = icon(findIconDefinition({ prefix: 'fas', iconName: 'right-to-bracket' }));
export const circle_plus = icon(findIconDefinition({ prefix: 'fas', iconName: 'circle-plus' }));
export const circle_minus = icon(findIconDefinition({ prefix: 'fas', iconName: 'circle-minus' }));

export const MiscItems = {
    up_right_arrow,
    circle_plus,
    circle_minus
};

// Export project icons
const bullhorn = icon(findIconDefinition({ prefix: 'fas', iconName: 'bullhorn' }));
const keyboard = icon(findIconDefinition({ prefix: 'fas', iconName: 'keyboard' }));
const summary = icon(findIconDefinition({ prefix: 'fas', iconName: 'text-height' }));
const story = icon(findIconDefinition({ prefix: 'fas', iconName: 'quote-right' }));

export const ProjectsIcons = {
    bullhorn,
    keyboard,
    summary,
    story
};