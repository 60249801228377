import React from 'react'
import {SectionLayout} from 'reusables/Layout'
import { Title, RowContainer} from 'reusables/style-elements'
import { PWrapper } from './style-elements'

import FlippingCard from 'reusables/FlippingCard'

const ServicesSection = ({name, data}) => {
    return (
        <SectionLayout name={name} primary={true}>
                <Title>Expertise</Title>
                <RowContainer>
                    <PWrapper>
                        {data.map((element, idx) => {
                            return (
                                <FlippingCard key={idx} title={element.title} image={element.img} metadata={element.metadata}/>
                            )
                        })}
                    </PWrapper> 
                </RowContainer>
        </SectionLayout>
    )
}

export default ServicesSection
