import React from 'react'
import styled from 'styled-components'
import {CustomIcon, i_facebook, i_linkedin, i_github, i_medium} from 'utils/library'

// Socials data
export const SocialsData = [
    {   
        link: 'https://google.com',
        icon: i_facebook,
    },
    {
        link: 'https://www.linkedin.com/company/neuraleaps-unlimited/',
        icon: i_linkedin
    },
    {
        link: 'https://github.com/Neural-Leaps-Unlimited',
        icon: i_github
    },
    {
        link: 'https://google.com',
        icon: i_medium
    }
]

// Socials styling
const SocialsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    max-width: 900px;
    margin: 1rem auto;
`

const SocialLink = styled.a`
    display: block;
    margin: 0 0.3rem;
    & :hover {
        color: ${props => props.theme.hoverColor};
    }
`


// Socials Component
function Socials(props) {
    return (
    <SocialsContainer 
        data-aos-offset="0"
        data-aos="fade-right" 
        data-aos-duration="500">
        {Object.keys(props.socialsData).map((item, i) => {
            return (
                <SocialLink key={i} href={props.socialsData[item].link}>
                    <CustomIcon color={props.color} icon={props.socialsData[item].icon}/>
                </SocialLink>
            )
        })}
    </SocialsContainer>
  )
}

export default Socials;