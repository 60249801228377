import React from 'react'
import Socials from 'reusables/Socials';
import ceo_avatar from 'assets/other/arazvant.jpeg'
import { PageLayout } from 'reusables/Layout';
import { Title } from "reusables/style-elements" 
import { APDescriptionContainer, APMemberCard, APTeamContainer } from './style-elements'
import { ABOUT } from 'data';

function AboutUsPage() {
  return (
        <PageLayout primary={false}>
            <APDescriptionContainer>
                <Title talign={"left"} fsize={3}>Our Company</Title>
                <p>
                    At <span>NeuraLeaps</span> we develop custom AI components for tech companies.
                    <br />
                    As a development and consulting company, we aim to build the path and provide small businesses with standalone or fullstack ML solutions integrated into their products. 
                </p>
                <p>
                    We streamline the process between analyzing the problem and deploying the solution either as SaaS in a cloud environment or on local targets of choice. Making use
                    of the lattest tech-stack in the field, we ensure a well-written, documented and scalable application.
                </p>
                <Title margin={"5rem 0 0 0"} fsize={3}>Meet the Team</Title>
                <APTeamContainer>
                    <APMemberCard>
                        <div className='avatar'>
                            <img src={ceo_avatar}/>
                        </div>
                        <h3>Alexandru Razvant</h3>
                        <p>Founder/CEO</p>
                        <Socials socialsData={ABOUT['arazvant']}/>
                    </APMemberCard>
                </APTeamContainer>
            </APDescriptionContainer>
        </PageLayout>
  )
}

export default AboutUsPage;