import React from 'react'
import {
    RatingContainer, 
    RatingRow,
    RRating,
    RRatingBars,
    RRatingBar,
    StyledRating
} from './style-elements'

import { Tooltip } from '@mui/material';

// Import reusable components
import { Title } from 'reusables/style-elements';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

function Ratings({total, ratings}) {

    const rating = Object.keys(ratings).map((rkey) => {
        return Number(rkey * ratings[rkey]['cnt'])
    })
    const ratingScore = Number(((rating.reduce((x, y) => x + y) / (total * 5)) * 5).toFixed(2))

    return (
        <RatingContainer>
            <Title talign={'left'} margin={"0 auto 0 0"} fsize={1.3}>Customer Reviews</Title>
            <RatingRow>
                <Title width={'auto'} talign={'left'} fsize={3.5} margin={"0 0 auto 0"}>{ratingScore}</Title>
                <RRating>
                    <StyledRating
                        precision={0.1}
                        readOnly
                        defaultValue={ratingScore}
                        icon={<FavoriteIcon fontSize="inherit" />}
                        emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
                    />
                </RRating>
                <h3>{total} reviews</h3>
            </RatingRow>
            <RRatingBars>
                {Object.keys(ratings).map((rkey, i) => {
                    const tooltip = `${ratings[rkey]['cnt']} voted ${rkey} stars`
                    return (
                    <Tooltip key={i} title={tooltip}>
                        <RRatingBar key={i}>
                            <h5>{rkey}</h5>
                            <div><div style={{width: `${(ratings[rkey]['cnt'] / total) * 100}%`, background: `${ratings[rkey]['color']}`}}/></div>
                            <h4>{Math.round((ratings[rkey]['cnt'] / total) * 100)}%</h4>
                            <h5>{ratings[rkey]['cnt']}</h5>
                        </RRatingBar>
                    </Tooltip>)
                })}
            </RRatingBars>
    </RatingContainer>
    )
}

export default Ratings
