import React from 'react'
import styled from 'styled-components'

const LoadingSVG = ({ className }) => {
    return (
        <div className={className} >
        <svg width="232" height="247" viewBox="0 0 232 247" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="z_third" d="M165.748 136.614H149.363C149.207 136.614 149.054 136.566 148.925 136.478C148.797 136.39 148.698 136.265 148.642 136.119C148.585 135.973 148.575 135.814 148.611 135.662C148.647 135.51 148.728 135.373 148.844 135.268L163.744 121.773H149.977C149.772 121.773 149.576 121.692 149.431 121.547C149.286 121.402 149.204 121.206 149.204 121.001C149.204 120.796 149.286 120.599 149.431 120.454C149.576 120.309 149.772 120.228 149.977 120.228H165.748C165.904 120.228 166.057 120.275 166.186 120.364C166.314 120.452 166.413 120.577 166.469 120.723C166.526 120.868 166.536 121.028 166.5 121.18C166.464 121.331 166.383 121.469 166.267 121.574L151.367 135.068H165.748C165.953 135.068 166.15 135.15 166.295 135.294C166.44 135.439 166.521 135.636 166.521 135.841C166.521 136.046 166.44 136.242 166.295 136.387C166.15 136.532 165.953 136.614 165.748 136.614V136.614Z" fill="#CBCBCB" />
            <path id="z_first" d="M177.339 86.3856H160.954C160.798 86.3856 160.645 86.3384 160.517 86.2501C160.388 86.1618 160.289 86.0366 160.233 85.8909C160.176 85.7453 160.166 85.586 160.202 85.4341C160.238 85.2823 160.319 85.1449 160.435 85.0402L175.335 71.5455H161.568C161.363 71.5455 161.167 71.4641 161.022 71.3192C160.877 71.1742 160.795 70.9777 160.795 70.7728C160.795 70.5678 160.877 70.3712 161.022 70.2263C161.167 70.0814 161.363 70 161.568 70H177.339C177.495 70 177.648 70.0472 177.777 70.1356C177.905 70.2239 178.004 70.3491 178.061 70.4948C178.117 70.6404 178.127 70.7996 178.091 70.9515C178.055 71.1033 177.974 71.2407 177.858 71.3455L162.958 84.8401H177.339C177.544 84.8401 177.741 84.9216 177.886 85.0665C178.031 85.2114 178.112 85.408 178.112 85.6129C178.112 85.8179 178.031 86.0144 177.886 86.1593C177.741 86.3042 177.544 86.3856 177.339 86.3856V86.3856Z" fill="#CBCBCB" />
            <path id="z_sec" d="M186.612 112.659H170.227C170.071 112.659 169.918 112.612 169.789 112.523C169.661 112.435 169.562 112.31 169.506 112.164C169.449 112.018 169.439 111.859 169.475 111.707C169.511 111.555 169.592 111.418 169.708 111.313L184.608 97.8187H170.841C170.636 97.8187 170.44 97.7373 170.295 97.5923C170.15 97.4474 170.068 97.2508 170.068 97.0459C170.068 96.841 170.15 96.6444 170.295 96.4995C170.44 96.3546 170.636 96.2732 170.841 96.2732H186.612C186.768 96.2732 186.921 96.3204 187.05 96.4088C187.178 96.4971 187.277 96.6223 187.333 96.7679C187.39 96.9136 187.4 97.0728 187.364 97.2247C187.328 97.3765 187.247 97.5139 187.131 97.6187L172.231 111.113H186.612C186.817 111.113 187.014 111.195 187.159 111.34C187.304 111.485 187.385 111.681 187.385 111.886C187.385 112.091 187.304 112.288 187.159 112.432C187.014 112.577 186.817 112.659 186.612 112.659V112.659Z" fill="#CBCBCB" />
            <g id="body">
                <path id="Vector" d="M103.067 231.021C126.853 231.021 146.135 211.739 146.135 187.954C146.135 164.168 126.853 144.886 103.067 144.886C79.2819 144.886 60 164.168 60 187.954C60 211.739 79.2819 231.021 103.067 231.021Z" fill="#2F2E41" />
                <path id="Vector_2" d="M153.969 217.558H115.385V230.642H153.969V217.558Z" fill="#2F2E41" />
                <path id="Vector_3" d="M154.241 230.642C156.499 230.642 158.33 225.76 158.33 219.739C158.33 213.717 156.499 208.836 154.241 208.836C151.983 208.836 150.153 213.717 150.153 219.739C150.153 225.76 151.983 230.642 154.241 230.642Z" fill="#2F2E41" />
                <path id="Vector_4" d="M144.969 216.558H106.385V229.642H144.969V216.558Z" fill="#2F2E41" />
                <path id="Vector_5" d="M145.241 229.642C147.499 229.642 149.33 224.76 149.33 218.739C149.33 212.717 147.499 207.836 145.241 207.836C142.983 207.836 141.153 212.717 141.153 218.739C141.153 224.76 142.983 229.642 145.241 229.642Z" fill="#2F2E41" />
                <path id="Vector_6" d="M119.62 185.502C117.367 185.547 115.157 184.884 113.302 183.606C111.447 182.328 110.04 180.499 109.28 178.378C109.238 178.254 109.221 178.122 109.23 177.991C109.239 177.86 109.274 177.732 109.333 177.615C109.391 177.497 109.472 177.392 109.571 177.306C109.67 177.22 109.785 177.154 109.91 177.112C110.034 177.07 110.166 177.053 110.297 177.062C110.428 177.071 110.556 177.106 110.673 177.165C110.791 177.223 110.896 177.304 110.982 177.403C111.068 177.502 111.134 177.617 111.176 177.742C112.69 182.25 117.874 184.607 122.731 182.995C125.142 182.21 127.145 180.503 128.303 178.248C128.8 177.276 129.099 176.215 129.182 175.127C129.266 174.039 129.132 172.946 128.789 171.91C128.747 171.785 128.73 171.654 128.739 171.523C128.748 171.392 128.783 171.264 128.841 171.146C128.9 171.029 128.981 170.924 129.08 170.837C129.179 170.751 129.294 170.685 129.418 170.643C129.543 170.601 129.674 170.585 129.805 170.594C129.936 170.603 130.064 170.637 130.182 170.696C130.299 170.754 130.404 170.835 130.491 170.934C130.577 171.033 130.643 171.148 130.685 171.273C131.112 172.558 131.28 173.916 131.179 175.267C131.077 176.617 130.708 177.934 130.092 179.141C128.695 181.872 126.275 183.939 123.36 184.894C122.154 185.295 120.891 185.5 119.62 185.502V185.502Z" fill="white" />
                <path id="Vector_7" d="M138.804 191.355C139.96 193.615 140.171 196.242 139.39 198.658C138.609 201.073 136.9 203.08 134.64 204.236C132.38 205.392 129.753 205.603 127.337 204.822C124.921 204.041 122.915 202.333 121.759 200.072L121.75 200.055C119.348 195.346 122.668 192.423 127.377 190.021C132.086 187.618 136.401 186.646 138.804 191.355Z" fill="white" />
                <path id="Vector_8" d="M93.4321 244.968C97.4433 243.934 98.0237 232.737 94.7284 219.96C91.4332 207.182 85.5102 197.663 81.499 198.697C77.4878 199.732 76.9074 210.928 80.2026 223.706C83.4979 236.483 89.4209 246.003 93.4321 244.968Z" fill="#2F2E41" />
            </g>
            <g id="head">
                <path id="Vector_9" d="M61.2905 147.903C57.813 132.329 68.9291 116.592 86.1191 112.754C103.309 108.915 120.063 118.429 123.541 134.003C127.018 149.576 115.626 155.32 98.436 159.159C81.246 162.997 64.768 163.476 61.2905 147.903Z" fill="#6C63FF" />
            </g>
            <path id="ground" d="M166.721 231H60.2794C60.2053 231 60.1342 230.947 60.0818 230.854C60.0294 230.76 60 230.633 60 230.5C60 230.367 60.0294 230.24 60.0818 230.146C60.1342 230.053 60.2053 230 60.2794 230H166.721C166.795 230 166.866 230.053 166.918 230.146C166.971 230.24 167 230.367 167 230.5C167 230.633 166.971 230.76 166.918 230.854C166.866 230.947 166.795 231 166.721 231Z" fill="#3F3D56" />
            <g id="birds">
                <path id="Vector_10" d="M219.119 123.377L231.914 113.143C221.974 112.047 217.89 117.468 216.219 121.758C208.453 118.534 200 122.76 200 122.76L225.6 132.053C224.309 128.604 222.06 125.594 219.119 123.377V123.377Z" fill="#3F3D56" />
                <path id="Vector_11" d="M19.1192 78.3768L31.9141 68.1433C21.9743 67.0467 17.8903 72.4676 16.2188 76.7583C8.45349 73.5338 0 77.7597 0 77.7597L25.6001 87.0534C24.3086 83.6039 22.0605 80.594 19.1192 78.3768V78.3768Z" fill="#3F3D56" />
                <path id="Vector_12" d="M141.119 10.3768L153.914 0.143314C143.974 -0.953306 139.89 4.46759 138.219 8.7583C130.453 5.53384 122 9.75968 122 9.75968L147.6 19.0534C146.309 15.6039 144.06 12.594 141.119 10.3768Z" fill="#3F3D56" />
            </g>
        </svg>
        </div>
    )
}

export const LoadingLogo = styled(LoadingSVG)`
    display: flex;
    justify-content: center;
    align-items: center;
    
    & #head > path, #birds > path {
        fill: ${props => props.theme.antiHoverColor};
    }

    & #z_first, #z_sec, #z_third {
        fill: ${props => props.theme.hoverColor} !important;
    }

    & #birds {
        animation: ${props => props.animate ? "fly 1.5s linear infinite alternate" : "none"};
        @keyframes fly {
            0% {
                transform: translateY(1px);
            }
            100% {
                transform: translateY(5px);
            }
        }
    }

    & #z_first, #z_third {
        animation: ${props => props.animate ? "floating_right 1s linear infinite alternate" : "none"};
        @keyframes floating_right {
            0% {
                opacity: 0.2;
                transform: translate(0px, 0px);
            }
            100% {
                opacity: 1;
                transform: translate(2px, 8px);
            }
        }
    }

    
    & #z_sec {
        animation: ${props => props.animate ? "floating_left 1s linear infinite alternate" : "none"};
        @keyframes floating_left {
            0% {
                opacity: 0.2;
                transform: translate(0px, 0px);
            }
            100% {
                opacity: 1;
                transform: translate(-4px, 8px);
            }
        }
    }

    & #ground {
        fill: ${props => props.theme.textPrimary} !important;
    }
`