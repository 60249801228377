import styled from 'styled-components'
import {Link as LinkS} from 'react-scroll'
import {Link as LinkR} from 'react-router-dom'

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: black;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({isOpen}) => (isOpen ? '100%' : '0%')};
    top: ${({isOpen}) => (isOpen ? '0' : '-100%')}
`

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`

export const SidebarWrapper = styled.div`
    color: #fff;
    margin: 0 auto;
    width: 90%;
`

export const SidebarLink = styled(LinkS)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    color: #fff;
    cursor: pointer;
    margin: 0 auto;
    width: 100%;
    background: ${props => props.theme.hoverColor}30;
    border-radius: 12px;

    &:hover {
        color: ${props => props.theme.hoverColor};
        background: ${props => props.theme.bgPrimary}20;
        transition: 0.2s ease-in-out;
    }
`

export const SideBtn = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 auto;
    height: 60px;
    width: 100%;

`

export const SidebarMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 60px);
    gap: 0.5rem;
    text-align: center;
    margin: 0 auto;
    padding: 0;
`