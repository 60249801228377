import React from 'react'
import device from './screen'
import styled from 'styled-components'
// Motion for animating transitions
import { motion } from 'framer-motion/dist/framer-motion';

const SectionLayoutContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 5rem 60px;
    margin-top: ${props => props.normalize ? "-70px" : 0};
    background: ${props => props.primary ? props.theme.bgPrimary : props.theme.bgSecondary};
    
    @media screen and ${device.tabletmax} {
      padding: 1rem 10px !important;
      margin-top: 0;
  }
`

const PageLayoutContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: 100vh;
    padding-top: 60px;
    background: ${props => props.primary ? props.theme.bgPrimary : props.theme.bgSecondary};
    margin: 0 auto;
    
    @media screen and ${device.tabletmax} {
      margin-top: 0;
  }
`

const PageLayoutContents = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    min-height: calc(100vh - 60px);
    width: 100%;
`

function SectionLayout(props) {
  return (
    <SectionLayoutContainer name={props.name} primary={props.primary} normalize={props.normalize}>
        {props.children}
    </SectionLayoutContainer>
  )
};

function PageLayout(props) {
  return(
      <PageLayoutContainer name={props.name} primary={props.primary} normalize={props.normalize}>
          <PageLayoutContents>
            {props.children}
          </PageLayoutContents>
      </PageLayoutContainer>
  )
};

export {SectionLayout, PageLayout}