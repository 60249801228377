// Define elements used in Footer
import styled from 'styled-components'
import screen from 'reusables/screen'
import { Link } from 'react-router-dom'

export const FBaseContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: #010606;
    padding: 2rem auto;
    min-height: 400px;
    margin: 0 auto;
    position: sticky;
    z-index: 99;

    @media screen and ${screen.tabletmax} {
        min-height: 200px;
    }
`

export const FContainer = styled.div`
    display: -ms-grid;
    display: grid;
    margin: 32px auto;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    grid-auto-columns: 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    text-align: left;
    max-width: 1100px;

    @media screen and ${screen.tabletmax} {
        grid-template-columns: 1fr; 
        padding: 0 20px;
    }
`

export const FLink = styled(Link)`
    display: block;
    padding-top: 12px;
    padding-bottom: 12px;
    color: white;
    font-weight: 400;
    text-decoration: none;
    font-family: ${props => props.theme.fontFamily};

    &: hover {
        transition: 0.1s all ease-out;
        text-decoration: underline;
    }
`
export const FContainerColumn = styled.div`
    display: block;

    h4 {
        font-family: ${props => props.theme.fontFamily};
        margin-top: 0;
        margin-bottom: 12px;
        color: ${props => props.theme.hoverColor};
        font-size: 15px;
        line-height: 1.5;
        font-weight: 500;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        margin-block-start: 1em;
        margin-block-end: 1em;
        padding-inline-start: 40px;
    }

    ul {
        margin-bottom: 32px;
        padding: auto;
        list-style: none;

        li {
            padding-top: 0;
            padding-bottom: 0;
            font-weight: 400;
        }
    }

    @media screen and ${screen.tabletmax} {
       display: none;
    }
`   

export const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
`

export const FColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 0;
    margin-right: auto;
    width: auto;

    @media screen and ${screen.tabletmax} {
        gap: 0.8rem;
     }

`
export const FTitle = styled.h3`
    color: white;
    font-family: ${props => props.theme.fontFamily};
    font-size: ${({size}) => (size ? `${size}px` : '15px')} !important;
    font-weight: 300;
    white-space: nowrap;
    text-align: center;
    margin: 0;
`

export const FItemsList = styled.ul`
    padding: 0;
    margin: 0;
    gap: 0.6rem;
    color: white;
    border: 1px solid blue;


    li {
        display: block;
        border: 1px solid green;

        a {
            color: white;
        }
    }
`
export const FContainerRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 1.8rem;
    max-width: 1100px;
    margin-bottom: 1rem;

    @media screen and ${screen.mobileLmax} {
        gap: 0.5rem;
        flex-direction: column;
    }
`