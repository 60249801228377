const SERVICES = [
  {
    "img": require("assets/services/computervision.svg"),
    "title": "Computer Vision",
    "description": "We make sure the code is clean and scalable",
    "metadata": "Computer vision is the field of study that focuses on enabling computers to interpret and understand visual information from the world, such as images and videos.It involves developing algorithms, techniques and models to extract meaningful information from digital images and videos."
  },
  {
    "img": require("assets/services/machinelearning.svg"),
    "title": "Machine Learning",
    "description": "We do data analysis, clustering, visualisation and interpretation",
    "metadata": "Machine learning is a method of teaching computers to learn and make predictions based on data. It involves feeding a computer large amounts of data, and allowing it to identify patterns and relationships within that data. "
  },
  {
  "img": require("assets/services/frontend_dev.svg"),
  "title": "Frontend",
  "description": "Scalable, clean react code.",
  "metadata": "A web dashboard is an interactive interface that displays and organizes data in a user-friendly format. It allows users to view, analyze, and manage data from multiple sources in one centralized location."
  
},{
  "img": require("assets/services/deeplearning.svg"),
  "title": "Deep Learning",
  "description": "Convolutional Neural Networks, Style Transfer, NLP",
  "metadata":"Deep learning is a subset of machine learning that uses neural networks with multiple layers to analyze and process data. These networks are designed to simulate the way the human brain processes information, allowing them to learn and improve over time."
},{
  "img": require("assets/services/backend_dev.svg"),
  "title": "Backend",
  "description": "FastAPI/Flask, asyncio backend microservices",
  "metadata": "Backend development is the process of building and maintaining the server-side of a web application. This includes the creation and management of databases, server-side logic, and APIs that allow the frontend of the application to communicate with the backend. "
},{
  "img": require("assets/services/cloudhosting.svg"),
  "title": "Deployment",
  "description": "We develop and deploy your solution in cloud environments",
  "metadata": "Deployment refers to the process of releasing a software application or system into a live environment, making it available for use by users or customers. This typically involves installing the software on a server or hosting platform, configuring it to work with existing infrastructure, and testing to ensure that it functions as expected."
}]

export default SERVICES;