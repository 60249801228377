import styled from 'styled-components'
import device from 'reusables/screen'
import { Link } from 'react-router-dom'

export const PContainerEncapsulate = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background: ${props => props.theme.Fourth };
`

export const PContainerContents = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;    
    grid-template-columns: repeat(4, 1fr);
    margin: 0 auto;
    padding-bottom: 3rem;
    gap: 1rem;

    @media screen and ${device.tabletmax} {
        grid-template-columns: repeat(2, 1fr);
        height: auto;
    }

    @media screen and ${device.mobileLmax} {
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 1rem;
        width: 90%;
        height: 100%;
    }
`

export const PItem = styled(Link)`
    display: flex;
    justify-conten: center;
    flex-direction: column;
    text-decoration: none;
    margin: 0 auto;
    padding: 0;
    width: 250px;
    min-height: 320px;
    box-shadow: 0 5px 5px rgb(0 0 0 / 20%);
    background: ${props => props.theme.bgSecondary};
    border-radius: 5px;
    overflow:hidden;
    cursor: pointer;
    transition: 0.2s all ease-in;

    &:hover {
        transition: 0.2s all ease-out;
        transform: scale(1.1);

        h3 {
            color:${props => props.theme.hoverColor};
        }
    }
    
    h3 {
        font-family: ${props => props.theme.fontFamily};
        color:${props => props.theme.textPrimary};
        padding: 0;
        margin-bottom: 0;
        text-align: center;
    }

`

export const PLogo = styled.img`
    display: block;
    height: 175px;
    width: 250px;
    margin: 0 auto;
    transition: 0.1s all ease-in-out;
`

export const PTag = styled.div`
    width: min-content;
    height: 0.8rem;
    border-radius: 1rem;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.textPrimary};
    background: ${props => props.color ? props.color : 'white'};
    transition: 0.2s all ease-out;
    
    p {
        color: #000;
        font-family: DM Sans;
        display: inline;
        font-size: 0.6rem;
        white-space: nowrap;
    }
`
export const PTagsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 0.5rem;
    gap: 0.2rem;
    margin: 0.8rem 0;
    overflow-x: hidden;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 60px;
    align-content: flex-start;

`

export const CContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.bgSecondary};
    margin: 0 auto;
    width: 100%;
    max-width: 1100px;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${props => props.theme.textPrimary};
`

export const CLogoItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    max-width: 200px;
    margin: 0 auto;

    img {
        margin: 0 auto;
        display: block;
        object-fit: contain;
        height: 100%;
    }
`