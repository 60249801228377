import styled from 'styled-components'
import device from '../../reusables/screen'

// AP = about page
export const APDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    width: 90%;
    max-width: 800px;

    p {
        font-family: ${props=> props.theme.fontFamily};
        color: ${props=> props.theme.textPrimary};
        font-size: 20px;
        margin-top: 0;
        margin-bottom: 5px;

        span {
            font-weight: 700;
            color: ${props => props.theme.hoverColor};
        }
    }
`

export const APMemberCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 200px;
    min-height: 300px;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.8);
    background-color: ${props => props.theme.bgPrimary};

    font-family: ${props=> props.theme.fontFamily};
    color: ${props=> props.theme.textPrimary};

    h3 {
        padding: 0;
        margin-top: 5px;
        margin-bottom: 0;
        font-size: 20px;
    }

    .avatar {
        img {
            display: block;
            width: 200px;
            height: 220px;
            margin: 0 auto;
            object-fit: cover;
            transition: 0.2s all ease-in-out;
            
            :hover {
                transition: 0.2s all ease-in-out;
                height: 260px;
            }
        }
    }
    p {
        width: 90%;
        text-align: center;
        font-size: 15px;
        margin: 0;
        padding: 0;

        
    }
`

export const APTeamContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.5rem;

    @media screen and ${device.mobileLmax} {
        flex-direction: column;
    }
`