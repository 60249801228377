import React, {useState, useEffect} from "react";
import { PContainerContents, PItem, PLogo, PTag, PTagsContainer, CContainer, CLogoItem} from "./style-elements";
import { Title, RowContainer } from "reusables/style-elements";
import { SectionLayout } from "reusables/Layout";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function PortfolioSection({ data }) {
  return (
    <SectionLayout primary={true}>
      <Title>Our work</Title>
      <RowContainer>
        <PContainerContents
          data-aos-offset="0"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          {data !== null
            ? data.map((item, idx) => {
                return (
                  <PItem key={idx} 
                  to={`/projects/${item.uri}`}
                  state={{metadata: item.blog, banner: item.banner, tags: item.tags}}
                  >
                    <PLogo src={item.banner}/>
                    <h3>{item.title}</h3>
                    <PTagsContainer>
                        {item.tags.map((tag, i) => {
                        return (
                            <PTag key={i} color={tag.color}>
                              <p>{tag.name}</p>
                            </PTag>
                        );
                        })}
                    </PTagsContainer>
                  </PItem>
                );
              })
            : null}
        </PContainerContents>
      </RowContainer>
    </SectionLayout>
  );
}

function ClientsSection({data}) {
  const [clientLogos, setClientLogos] = useState([])

  useEffect(() => {
    setClientLogos(data)
  },[data])

  return (
    <SectionLayout name="clients" primary={false}>
    <CContainer>
      <Title>Trusted by</Title>
      <OwlCarousel
      dots={false}
      autoplay={true}
      loop={true}
      items={5}
      autoplaySpeed={3000}
      autoplayTimeout={3000}
      smartSpeed={500}
      fluidSpeed={500}
      responsive={{
        0: {
          items: 1,
        },
        425: {
          items: 2
        },
        768: {
          items: 4
        }
      }}
    >
      { clientLogos ? data.map((item, i) => {
      return (
            <CLogoItem key={i}>
                <img src={item}/>
            </CLogoItem>
            )
    }) : null}
       </OwlCarousel>
    </CContainer>
  </SectionLayout>
  )
}
export {PortfolioSection, ClientsSection};
