import styled from 'styled-components'
import {ReactComponent as PatternSVG} from 'assets/projects/pattern.svg';
import device from 'reusables/screen'

export const ContentContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 1100px;
    margin: 0 auto;
`

export const Pattern = styled(PatternSVG)`
    opacity: 0.6;
    width: 100%;
    #lines {
        fill: ${props => props.theme.hoverColor};
    }
`

export const ProjectTitleDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1100px;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.textPrimary};
    font-family: ${props => props.theme.fontFamily};
    color: ${props => props.theme.textPrimary};
    
    h1 {
        font-size: 44px;
        line-height: 60px;
        font-weight: 700;
        margin: 0 auto 0 0;
    }

    p {
        color: ${props => props.theme.hoverColor};
        font-size: 14px;
        text-transform: uppercase;
    }
`

export const BannerImage = styled.img`
    object-fit: contain;
    display: flex;
    border-radius: 10px;
    margin-left: 1rem;
    margin-right: auto;
    margin-top: -10%;
    width: 80%;
`


export const Paragraphs = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 2rem auto 0;
    width: 100%;
    max-width: 800px;
`

export const TextContent = styled.p`
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 24px;
`

export const Paragraph = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: 3rem;
    max-width: 700px;
    color: ${props => props.theme.textPrimary};
    font-family: ${props => props.theme.fontFamily};

`

export const TitlePlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    background: ${props => props.theme.bgSecondary};
    min-height: 500px;
`

export const Quote = styled.p`
    border-left: 8px solid ${props => props.theme.antiHoverColor};
    color: ${props => props.theme.hoverColor};
    font-weight: 600;
    font-family: sans-serif;
    margin: 1rem auto;
    padding: 10px 20px;
    font-size: 20px;
    line-height: 32px;
`

export const TagsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin-top: 0.5rem;
    max-width: 1100px;
    width: 100%;

    #title_before {
        margin: 0;
        font-family: ${props=>props.theme.fontFamily};
        color: ${props=>props.theme.textPrimary};
        font-size: 15px;
        font-weight: 700;
    }
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    overflow-y: scroll;
    margin-bottom: 2rem;

    @media screen and ${device.mobileLmax} {
        margin: 0 auto;
        justify-content: center;
        align-items: center;

        & ${ProjectTitleDiv} {
            min-width: 0px;
            width: 90%;
            margin: 0;
        }

        & ${TagsContainer} {
          display: none;
        }

        & ${BannerImage} {
            display: block;
            padding: 0 auto;
            margin-top: -10%;
            width: 90%;
        }

        & ${Paragraphs} {
            margin: 0 auto;
            width: 90%;

            & ${Paragraph} {
                margin: 0 auto;
            }
        }
    }
`