import React, {useState, useEffect} from 'react'
import { useLocation } from "react-router";
import { Container, 
         Pattern, 
         BannerImage,
         TitlePlaceholder,
         ContentContainer, 
         ProjectTitleDiv, 
         Quote,
         TextContent,
        Paragraphs,
        TagsContainer,
        Paragraph} from './style-elements'
import { PageLayout } from 'reusables/Layout';
import { PTag } from 'components/collaborations/style-elements';


function BlogPage() {
    const location = useLocation()
    const [pageTitle, setPageTitle] = useState('')
    const [pageContents, setPageContents] = useState([])
    const [banner, setBanner] = useState(null)
    const [readTime, setReadTime] = useState(1)
    const [introText, setIntro] = useState('')
    const [tags, setTags] = useState([])

    useEffect(()=>{
        const state = location.state
        setPageContents(state.metadata.content)
        setPageTitle(state.metadata.title)
        setReadTime(state.metadata.readtime)
        setIntro(state.metadata.intro)
        setBanner(state.banner)
        setTags(state.tags)
        console.log(state.tags)
    },[])
    
    return (
        <PageLayout primary={true}>
            <Container>
                <TitlePlaceholder>
                    <ProjectTitleDiv>
                        <h1>{pageTitle}</h1>
                        <p>{readTime} read</p>
                    </ProjectTitleDiv>
                    <TagsContainer>
                        <p id="title_before">Using: </p>
                        {tags.map((tag, i) => {
                            return (
                                <PTag key={i} color={tag.color}>
                                    <p>{tag.name}</p>
                                </PTag>
                            );
                            })}  
                    </TagsContainer>
                </TitlePlaceholder>
                <ContentContainer>
                    <BannerImage src={banner}/>
                    <Paragraphs>
                        <Paragraph>
                            <TextContent>{introText}</TextContent>
                        </Paragraph>
                        {pageContents.map(pair => {
                            console.log(pair)
                            return (
                                <Paragraph>  
                                    <Quote>{pair.quote}</Quote>
                                        {pair.paragraphs.map((line)=> {
                                            return <TextContent>{line}</TextContent>
                                        })}
                                </Paragraph>
                            )
                        })}
                    </Paragraphs>
                </ContentContainer>
            </Container>
        </PageLayout>
    )
}

export default BlogPage
