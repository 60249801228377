import React, {useState} from "react";
import styled from 'styled-components'
import { Title } from "./style-elements";
import { CustomIcon, i_exit} from 'utils/library'
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion'
import device from './screen'

// Styling
const ModalContainerFixed = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 99;
`

const ModalContainer = styled.div`
    display: flex;
    margin: 0 auto;
    overflow-y: initial !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position:absolute;
    z-index: 999;
    background: ${props => props.theme.bgPrimary};
    width: 80%;
    max-width: 800px;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border-radius: ${props => props.br ? props.br : "1rem"};
    transition: 0.5s all ease-in-out;
    padding: ${props => props.padding ? props.padding : "0"};

    @media screen and ${device.tabletmax} {
        border-radius: 1rem;
     }

    @media screen and ${device.mobileLmax} {
        padding: 1rem;
     }
`

export const ModalImage = styled.div`
    display: flex;
    justify-content: center;

    img {
        width: 100%;
    }
    svg {
        max-height: 350px !important;
        max-width: 320px !important;
        display: block;
        object-fit: contain;
    }

`

const ModalElement = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-right: ${props => props.br ? props.br : '0'};
    width: 100%;

    @media screen and ${device.tabletmax} {
        & ${ModalImage}, .disposable {
           display: none;
        }
     }
`



export const ModalDescription = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 50% !important;
    height: 350px;
    ul {
        display: inline-block;
        padding: 0;
        width: 80%;
        margin: 0 auto;

        li {
            display: list-item;
            list-style: none;
            margin: 0 auto;
            color:  ${props => props.theme.textSecondary};
            font-family: ${props => props.theme.fontFamily};
            text-align: center;
            :hover {
                background: ${props => props.theme.antiHoverColor}30;
            }

            a {
                color:  ${props => props.theme.hoverColor};
                list-style: none;
                text-decoration: none;
            }
        }
    }

    p {
        display: inline-block;
        font-family: ${props => props.theme.fontFamily};
        color: ${props => props.theme.textPrimary};
        font-size: 18px;
        text-align: center;
    }

    @media screen and ${device.laptopLmax} {
        width: 100%;
        & ${Title} {
            margin-top: 1rem;
            text-align: center;
        }
     }

     @media screen and ${device.tabletmax} {
        width: 100% !important;
        height: auto;
     }

`

const ModalCloseButtonContainer = styled.div`
    position: absolute;
    top: 1rem;
    left: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    width: auto;
`

export const ModalItems = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    border-${props=> props.side}: 2px solid ${props => props.theme.hoverColor};
`
export const Modal = ({ handleClose, children, br, padding}) => {
    const modalAnimation = {
        initial: { y: "0", opacity: 0, scale: 0.5 },
        animate: { y: "50vh", opacity: 1, scale: 1, transition: {
                      duration: 0.2,
                      ease: "easeOut"}}
      };

    return (
            <ModalContainerFixed onClick={handleClose}>
                    <motion.div {...modalAnimation}>
                    <ModalContainer br={br} padding={padding} onClick={e => e.stopPropagation()}>
                        <ModalCloseButtonContainer>
                            <CustomIcon s={"1"} icon={i_exit} onClick={handleClose}/>
                        </ModalCloseButtonContainer>
                        <ModalElement>  
                            {children}
                        </ModalElement>
                    </ModalContainer>
                </motion.div>
            </ModalContainerFixed>
    );
  };
