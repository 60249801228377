import React, { useState, useEffect } from "react";
import {
  Nav,
  NavImage,
  NavbarContainer,
  NavLink,
  NavLogo,
  NavItem,
  NavLinksContainer,
  NavLogoText,
  MobileIcon,
  NavRLink,
  ThemeSwitchContainer,
} from "./style-elements";

import { DarkModeSwitch } from "react-toggle-dark-mode";
import { CustomIcon, NavbarIcons } from "utils/library";

const Navbar = ({ themeCallback , toggle}) => {
  const [isDarkMode, setDarkMode] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    // find current scroll position
    const currentScrollPos = window.pageYOffset;

    // set state based on location info (explained in more detail below)
    setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 80) || currentScrollPos < 10);

    // set state to new scroll position
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);

  }, [prevScrollPos, visible, handleScroll])

  const toggleDarkMode = (checked) => {
    setDarkMode(checked);
    themeCallback(isDarkMode);
  };

  return (
      <Nav>
        <NavbarContainer >
          <NavLogo to="/">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto;",
              }}
            >
              <NavLogoText>NEURA</NavLogoText>
              <NavLogoText>
                <span>LEAPS</span>
              </NavLogoText>
            </div>
            <NavImage src={require("assets/logo/logo_outline.svg")} />
          </NavLogo>
          <NavLinksContainer>
            <NavItem>
              <NavRLink href="/reviews">Reviews</NavRLink>
            </NavItem>
            <NavItem>
              <NavRLink href="/projects">Portfolio</NavRLink>
            </NavItem>
            <NavItem>
              <NavRLink href="/aboutus" to>About Us</NavRLink>
            </NavItem>
            <NavItem>
              <NavRLink href="https://form.jotform.com/222765738800360">Contact</NavRLink>
            </NavItem>
          </NavLinksContainer>
          <ThemeSwitchContainer>
            <DarkModeSwitch
              checked={isDarkMode}
              sunColor="white"
              moonColor="#3683a0"
              onChange={toggleDarkMode}
              size={30}
            />
          </ThemeSwitchContainer>
          <MobileIcon onClick={toggle}>
            <CustomIcon s={"30px"} color="#fff" icon={NavbarIcons.bars} />
          </MobileIcon>
        </NavbarContainer>
      </Nav>
  );
};

export default Navbar;
