const Tags = {
    ComputerVision: {
        "name": "Computer Vision",
        "color": "#fcf7de"
    },
    NLP : {
        "name": "Natural Language Processing",
        "color": "#80D87F"
    },
    OCR : {
        "name": "Optical Character Recognition",
        "color": "#80D87F"
    },
    QR : {
        "name": "QR Decoding",
        "color": "#80D87F"
    },
    Backend: {
        "name": "Backend Development",
        "color": "#E3C2C1"
    },
    DeepLearning: {
        "name": "Deep Learning",
        "color": "#46B1EF"
    },
    Frontend: {
        "name": "Frontend Development",
        "color": "#def3fd"
    }
}

const PROJECTS = [{
    "title": "Receipt Analyzer",
    "tags": [
        Tags.ComputerVision,
        Tags.QR,
        Tags.OCR
    ],
    "banner": require('assets/projects/qr_detector.jpeg'),
    "uri": "recparse",
    "blog": {
        "title": "Using AI to extract informations from receipts and other documents",
        "readtime": "1 min",
        "intro": "Receipt processing refers to the process of capturing, collecting, and processing receipts or invoices for business transactions. It is typically done by businesses to keep track of their expenses and revenue, and to ensure that they are accurate and up-to-date.",
        "content": [{
          "quote": "OCR (Optical Character Recognition) is a technology that allows computers to recognize text from images, such as scanned documents or photos.",
          "paragraphs": ["AI (Artificial Intelligence) can be used to improve the accuracy and efficiency of OCR by training machine learning models to recognize patterns in the text and identify specific fields, such as the date, total amount, and vendor.",
                         "When it comes to processing receipts, OCR is used to extract the text from the image of the receipt, and then AI is used to understand the structure of the receipt and extract relevant information such as date, total amount, and items purchased.",
                         "This information can then be used to automate bookkeeping tasks, such as creating expense reports or updating accounting records."]
        }]
    }
  },
  {
    "title": "Cars Monitoring System",
    "tags": [
        Tags.ComputerVision,
        Tags.DeepLearning
    ],
    "banner": require('assets/projects/car_license_detection.jpeg'),
    "uri": "carlpr",
    "blog": {
        "title": "Monitor Cars Count and extract License Plate Numbers",
        "readtime": "1 min",
        "intro": "In this project, computer vision and AI models are used to analyze video feeds from cameras placed on roads to track the movement of cars. This information can be used to monitor traffic flow and identify potential congestion or accidents.",
        "content": [{
          "quote": "Computer vision is a technology that allows computers to interpret and understand visual information from the world around them, such as images and videos. ",
          "paragraphs": ["This technology can be used to either count cars passing through a specific region, to extract and save images for each car detected or to retrieve information like License Plate, car color, time spent.",
                         "For License Plate detection, once a car was detected by the AI, it automatically reads and identifies the license plate numbers of cars passing through a specific area. This information can be used to track the movement of specific vehicles or to identify stolen cars."]
        }]
    }
  },
  {
    "title": "Smart Study",
    "tags": [
        Tags.DeepLearning,
        Tags.NLP,
        Tags.Frontend,
        Tags.Backend
    ],
    "banner": require('assets/projects/smart_study.jpeg'),
    "uri": "smartstudy",
    "blog": {
        "title": "Using AI to generate summaries, quiz tests, notes from text",
        "readtime": "1 min",
        "intro": "With the latest developments of language understanding AI, various tasks like text summarization, quiz generation, email and text auto-correction as well as many others started to become largely used to ease-up writing.",
        "content": [{
          "quote": "NLP, or Natural Language Processing, is a field of artificial intelligence that focuses on the interaction between computers and human language. ",
          "paragraphs": ["This project consisted of developing a web dashboard aiming at helping students at better understanding their school subjects. It provides an easy-to-use interface, where you can enter a text input and extract a meaningful summary or to generate sets of questions based on the text as well as extracting important notes. ",
                         "Language models like GPT-3, can do not only that but many more tasks, like writing blog posts, articles, social media posts, ads, explaining complex terms and more. With the current stage of the NLP models, pretty much any task that involves text processing is possible.",]
        }]
    }
  },
  {
    "title": "Sentiment Analysis",
    "tags": [
        Tags.ComputerVision,
        Tags.DeepLearning,
        Tags.NLP
    ],
    "banner": require('assets/projects/face_sentiment_analysis.png'),
    "uri": "sentimentanalysis",
    "blog": {
        "title": "Extract overall sentiment from social media posts or video feed.",
        "readtime": "1 min",
        "intro": "The purpose of AI sentiment analysis is to determine the emotional tone or attitude expressed in a piece of text, such as a social media post or customer review.",
        "content": [{
          "quote": "Sentiment analysis is the process of using natural language processing, text analysis, and computational linguistics to identify and extract subjective information from source materials.",
          "paragraphs": ["Companies can use sentiment analysis to understand how customers feel about their products or services and make improvements accordingly.",
                         "Determining the overall sentiment of a document, passage, or social media post (positive, negative, neutral), as well as identifying specific emotions and opinions expressed within the text, can provide very informative highlights. Sentiment analysis is often used in social media monitoring, market research, and customer feedback analysis to gain insights into public opinion and consumer sentiment.",
                         "This behaviour can be extended to video feeds also, as sentiment can be predicted from images in the same manner. Here a computer vision model will detect people in the images, and analyze their face expressions and make a prediction of the overall sentiment. The area of applications can thus be extended to various scenarios."]
        }]
    }
  },
  {
    "title": "Multi Purpose Detector",
    "tags": [
        Tags.ComputerVision,
        Tags.DeepLearning
    ],
    "banner": require('assets/projects/instance_segmentation.png'),
    "uri": "multipurpose",
    "blog": {
        "title": "High quality delimitations of objects in images/video",
        "readtime": "1 min",
        "intro": "Instance segmentation is a branch of computer vision, that can be used to delimit objects in the image/video. It can be applied to extract the high quality outline of different objects in images, being a widely used technology in robotics, autonomous driving, object counting and more.",
        "content": [{
          "quote": "Segmentation is used to identify and segment specific instances of objects within an image or video.",
          "paragraphs": ["This allows for various complex problems to be automated and use computer vision to solve them. Problems like object counting, removing objects from images, replace objects in images, autonomous driving systems, surveillance systems are a great examples where this technology is widely used."]
        }]
    }
  },
  {
    "title": "Face Identification",
    "tags": [
        Tags.ComputerVision,
        Tags.DeepLearning
    ],
    "banner": require('assets/projects/face_detection.jpeg'),
    "uri": "faceai",
    "blog": {
        "title": "Person authentication using face detection and recognition",
        "readtime": "1 min",
        "intro": "Security measures and authentication procedures have changed a lot in the past years, with the development with AI technologies for face recognition and face detection. Starting from Apple's Face ID, to Snapchat face filters, these AI models have seen great progress.",
        "content": [{
          "quote": "Face recognition is a technology that uses computer algorithms to identify and verify a person's identity based on their facial features. ",
          "paragraphs": ["Face detection implies detecting people in video feed and searching for face features in an area called Region of Interest, specifically where the person was detected.",
                         "Further, face recognition algorithms compares specific facial features, such as the distance between the eyes, nose, and mouth, and the shape of the jawline and forehead. These features are then compared to a database of pre-existing images, such as passport or ID photos, to determine if there is a match. The technology can be used in various applications, such as security systems, access control, and social media tagging.",
                         "It can also be used in biometric systems, such as facial recognition-enabled smartphones, to unlock the device or authenticate payments."]
        }]
    }
  },
  {
    "title": "AI Anti-Theft System",
    "tags": [
        Tags.ComputerVision,
        Tags.DeepLearning
    ],
    "banner": require('assets/projects/grocery_theft.png'),
    "uri": "antitheft",
    "blog": {
        "title": "Using AI to prevent theft events in retail systems",
        "readtime": "1 min",
        "intro": "Retail is one of the biggest sectors, and due to a large number of customers simultaneously, theft actions become harder to identify as it's hard to keep track of many events at the same time.",
        "content": [{
          "quote": "AI surveillance refers to the use of artificial intelligence (AI) technology to monitor and analyze video footage, registering and generating statictics on the observed events.",
          "paragraphs": ["Using a system based on AI & Computer Vision technologies, one can detect people from video feed, and identify if they carry objects/products of interest that were not validated.",
                         "Alongside visual data, various metrics and statistics can be extracted, such as identifying people who spent a long time in a specific region of the store or people who didn't stop a the self-checkout regions, sending alerts to a dashboard or to a mobile application."]
        }]
    }
  }
]
  
  export { Tags };
  export default PROJECTS;