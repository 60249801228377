import React from 'react'
import { PROJECTS } from 'data'
import { PageLayout } from 'reusables/Layout'
import { PContainerContents, PItem, PLogo, PTag, PTagsContainer} from "components/collaborations/style-elements";
import { RowContainer } from "reusables/style-elements";

function ProjectsPage() {
  return (
    <PageLayout>
        <RowContainer>
            <PContainerContents>
            {PROJECTS.map((item, idx) => {
                    return (
                    <PItem key={idx} 
                    to={`/projects/${item.uri}`}
                    state={{metadata: item.blog, banner: item.banner, tags: item.tags}}
                    >
                        <PLogo src={item.banner}/>
                        <h3>{item.title}</h3>
                        <PTagsContainer>
                            {item.tags.map((tag, i) => {
                            return (
                                <PTag key={i} color={tag.color}>
                                <p>{tag.name}</p>
                                </PTag>
                            );
                            })}
                        </PTagsContainer>
                    </PItem>
                    );
                })
            }
            </PContainerContents>
      </RowContainer>
    </PageLayout>
  )
}

export default ProjectsPage