import styled from 'styled-components'
import device from 'reusables/screen'

export const TSContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0px auto;
    min-height: 600px;
    padding: 5.6rem 60px;
    background: ${props => props.background};
`

export const TSRowContainer = styled.div`
    display: flex;
    width 100%;
    max-width: 1100px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
`

export const TSColumnContainer = styled.div`
    width: 50%;
    margin: 0 auto;

    @media screen and (max-width: 1000px) {
        width: 100%;
        &#title {
            display: none;
        }
    }
`
export const TSElementsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    align-items: center;
    margin: auto;
    grid-row-gap: 1.5rem;
    grid-column-gap: 1.5rem;
    width: 90%;


    @media screen and ${device.tabletmax} {
        grid-template-columns: repeat(3, 1fr);
        height: auto;
    }

    @media screen and ${device.mobileLmax} {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1rem;
        width: 90%;
        height: 100%;
    }
   
`
export const TSItem = styled.a`
    display: flex;
    margin: auto;
    height: 92px;
    width: 120px;

    @media screen and ${device.mobileLmax} {
        height: 45px;
        padding: 0;
    }
`

export const TSLogo = styled.img`
    display: block;
    object-fit: contain;
    margin: auto;
    width: 95%;
    height: 95%;

    @media screen and ${device.mobileLmax} {
        height: 100%;
        width: 100%;
    }

    :hover {
        transform: scale(1.05);
    }
`