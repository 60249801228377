const FOOTER = {
    logo: require("assets/logo/logo_outline.svg"),
    elements: [
        {
            headerTitle: "COMPANY",
            subLinks: [
                {
                    title: 'About Us',
                    link: '/aboutus'
                },
                {
                    title: 'Resources',
                    link: '/careers'
                },
                {
                    title: 'Blog',
                    link: '/blog'
                }]
        },

    ]
}

export default FOOTER;