import React from 'react'
import {HSContainer, 
        HSBackgroundVideo, 
        HSCenterTitle, 
        HSContent, 
        HSBackground, 
        HSCenterDescription, 
        HSStartButton} from './style-elements'

import hero_video from 'assets/videos/hero_light.mp4'
function HeroSection() {
    return (
        <HSContainer>
            <HSBackground>
                <HSBackgroundVideo height="1920" width="1080" src={hero_video} autoPlay loop playsinline muted style={{pointerEvents: "none"}}/>
            </HSBackground>
            <HSContent>
                <HSCenterTitle 
                data-aos="fade-up" 
                data-aos-duration="1000"
                data-aos-offset="0">Take a leap of f<span>AI</span>th.</HSCenterTitle>
                <HSCenterDescription
                data-aos="fade-left" 
                data-aos-duration="1000">The AI trend is being steadily adopted, thanks to the value it can provide. Let's see how it can help your business!</HSCenterDescription>
                <HSStartButton
                to="roadmap" 
                smooth={true} 
                duration={500}  
                >Start here</HSStartButton>
            </HSContent>
        </HSContainer>
    )
}

export default HeroSection;
