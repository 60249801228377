import React from 'react'
import styled from 'styled-components'
import device from 'reusables/screen'
import { Link } from 'react-scroll'

const WorkspaceSVGBase = ({ className, width, height}) => {
    return (
        <div className={className} >
            <svg  viewBox="0 0 4096 4096" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="group">
                    <path id="background" opacity="0.5" d="M0.399866 2452.25C-13.112 2937.04 317.501 3404.46 735.834 3491.78C1007.25 3548.53 1305.16 3464.63 1550.84 3607.11C1702.15 3694.8 1810.57 3856.72 1955.44 3957.62C2138.28 4084.81 2362.95 4104.38 2577.42 4093.37C2804.66 4081.88 3045.62 4032.22 3211.52 3855.01C3408.73 3644.17 3453.87 3304.06 3623.84 3064.35C3765.93 2863.9 3993.81 2734.15 4070.81 2492.36C4141.59 2269.66 4055.47 2012.83 3909.1 1844.79C3762.72 1676.75 3565.94 1580.26 3371.84 1495.26C3177.74 1410.26 2979.88 1331.01 2812.81 1187.07C2692.49 1083.48 2593.83 936.723 2456.35 860.776C2140.11 686.133 1629.98 674.515 1326.6 880.221C932.722 1147.44 799.533 1770.8 391.602 2009.28C260.236 2086.08 94.3398 2130.6 30.4263 2282.5C8.33544 2334.84 2.00843 2394.15 0.399866 2452.25"/>
                    <g id="monitor">
                        <path id="Vector_2" opacity="0.4" d="M2348.22 2574.35L2008.31 2350.61C1983.57 2334.52 1986.91 2306.31 2015.18 2287.69L2235.4 2142.73L2664.01 2424.88L2443.8 2569.84C2415.53 2588.46 2372.72 2590.51 2348.22 2574.35Z" fill="black" />
                        <path id="Vector_3" d="M2378.84 2553.69L2039 2329.94C2014.25 2313.79 2017.59 2285.64 2045.86 2266.95L2266.08 2121.99L2694.63 2404.14L2474.48 2549.1C2446.15 2567.79 2403.34 2570.05 2378.84 2553.69Z" fill="#B1B4C4" />
                        <path id="Vector_4" d="M2694.63 2404.43V2389.61L2674.9 2402.59L2694.63 2404.43Z" fill="#B1B4C4" />
                        <path id="Vector_5" d="M2022.48 2303.49V2288.39L2042.77 2298.06L2022.48 2303.49Z" fill="#B1B4C4" />
                        <path id="Vector_6" d="M2378.84 2539.08L2039 2315.34C2014.25 2299.18 2017.59 2271.04 2045.86 2252.42L2266.08 2107.39L2694.63 2389.54L2474.48 2534.5C2446.15 2553.19 2403.34 2555.24 2378.84 2539.08Z" fill="#D6D8E5" />
                        <path id="Vector_7" d="M2267.19 2108.1L2214.18 1907.42L2647.43 2190.77L2694.63 2389.61L2267.19 2108.1Z" fill="#9EA1AF" />
                        <path id="Vector_8" d="M2682.07 2397.87L2694.44 2389.61L2675.89 2372.61L2682.07 2397.87Z" fill="#9EA1AF" />
                        <path id="Vector_9" d="M2253.52 2115.64L2200.51 1914.96L2633.76 2198.31L2682.07 2397.87L2253.52 2115.64Z" fill="#B1B4C4" />
                        <path id="Vector_10" d="M3320.08 2719.1L1642.92 1614.89C1619.41 1599.45 1600.36 1557.9 1600.36 1522.14V42.5274C1600.36 6.76459 1619.41 -9.6708 1642.92 5.8476L3320.08 1110.19C3343.58 1125.71 3362.63 1167.26 3362.63 1203.02V2682.35C3362.63 2718.11 3343.58 2734.62 3320.08 2719.1Z" fill="#D6D8E5" />
                        <path id="Vector_11" d="M3328.67 2734.33L3347.29 2722.13L3339.25 2659.28L3328.67 2734.33Z" fill="#B1B4C4" />
                        <path id="Vector_12" d="M1597.33 15.1587L1615.89 3.09668L1642.18 20.1669C1642.18 20.1669 1614.16 25.1046 1613.48 24.893C1612.79 24.6814 1597.33 15.1587 1597.33 15.1587Z" fill="#B1B4C4" />
                        <path id="Vector_13" d="M3304.67 2729.26L1627.58 1625.05C1604.01 1609.53 1584.96 1567.99 1584.96 1532.22V52.8964C1584.96 17.1336 1604.01 0.698167 1627.58 16.146L3304.67 1120.35C3328.24 1135.8 3347.29 1177.35 3347.29 1213.11V2692.5C3347.29 2728.27 3328.24 2744.7 3304.67 2729.26Z" fill="black" />
                        <path id="Vector_14" d="M3301.77 2731.16L1624.61 1626.96C1601.1 1611.51 1581.99 1569.96 1581.99 1534.2V54.8017C1581.99 19.0388 1601.1 2.60344 1624.61 18.0513L3301.77 1122.26C3325.27 1137.77 3344.32 1179.32 3344.32 1215.08V2694.76C3344.32 2730.03 3325.27 2746.61 3301.77 2731.16Z" fill="#D6D8E5" />
                        <path id="Vector_15" d="M3321.07 2641.15L1603.33 1510.14L1604.2 60.7263C1604.2 45.9133 1613.35 39.5649 1624.61 47.324L3301.77 1151.46C3307.71 1155.88 3312.66 1161.84 3316.2 1168.85C3319.75 1175.86 3321.8 1183.72 3322.18 1191.81L3321.07 2641.15Z" fill="black" />
                        <path id="Vector_16" opacity="0.5" d="M3321.07 2641.15L1603.33 1510.14L1604.2 60.7263C1604.2 45.9133 1613.35 39.5649 1624.61 47.324L3301.77 1151.46C3307.71 1155.88 3312.66 1161.84 3316.2 1168.85C3319.75 1175.86 3321.8 1183.72 3322.18 1191.81L3321.07 2641.15Z" fill="url(#paint0_linear_359_11)" />
                    </g>
                    <path id="Vector_17" opacity="0.4" d="M1804.43 3429.56L819.961 2780.61C805.919 2771.37 807.589 2755.28 823.858 2744.56L1191.97 2502.26C1208.18 2491.54 1232.68 2490.41 1246.72 2499.65L2231.19 3148.6C2245.23 3157.84 2243.56 3174 2227.29 3184.65L1859.18 3427.02C1842.97 3437.67 1818.47 3438.8 1804.43 3429.56Z" fill="black" />
                    <path id="Vector_18" d="M2244.36 3132.1L1264.29 2486.32C1250.31 2477.08 1225.75 2478.28 1209.6 2488.93L843.096 2730.03L828.188 2723.47V2750.27C828.084 2753.86 828.917 2757.39 830.58 2760.43C832.244 2763.47 834.662 2765.88 837.529 2767.35L1822.06 3416.3C1836.1 3425.54 1860.6 3424.34 1876.8 3413.69L2244.74 3171.25C2253.83 3165.25 2258.41 3157.56 2258.16 3150.09V3123.28L2244.36 3132.1Z" fill="#B1B4C4" />
                    <path id="Vector_19" d="M1822.06 3389.42L837.529 2740.47C823.487 2731.23 825.157 2715.15 841.488 2704.43L1209.6 2461.99C1225.75 2451.26 1250.31 2450.14 1264.29 2459.38L2248.82 3108.33C2262.86 3117.57 2261.19 3133.72 2244.86 3144.37L1876.8 3386.74C1860.6 3397.53 1836.1 3398.66 1822.06 3389.42Z" fill="#D6D8E5" />
                    <path id="Vector_20" d="M1183.5 2581.9L1135 2549.95C1131.04 2547.34 1131.54 2542.89 1136.12 2539.79L1182.39 2509.32C1184.72 2507.82 1187.34 2506.98 1190.01 2506.86C1192.69 2506.74 1195.35 2507.34 1197.79 2508.61L1246.29 2540.49C1250.25 2543.1 1249.75 2547.55 1245.17 2550.65L1198.9 2581.12C1196.57 2582.63 1193.96 2583.48 1191.28 2583.62C1188.61 2583.75 1185.94 2583.16 1183.5 2581.9V2581.9Z" fill="#2B303F" />
                    <path id="Vector_21" d="M1252.16 2627.04L1203.67 2595.16C1199.71 2592.55 1200.2 2588.11 1204.78 2585L1251.05 2554.53C1253.39 2553.04 1256 2552.2 1258.68 2552.08C1261.35 2551.95 1264.02 2552.55 1266.45 2553.83L1314.95 2585.71C1318.91 2588.32 1318.41 2592.76 1313.84 2595.87L1267.38 2626.34C1265.07 2627.8 1262.49 2628.62 1259.84 2628.74C1257.2 2628.87 1254.57 2628.28 1252.16 2627.04V2627.04Z" fill="#2B303F" />
                    <path id="Vector_22" d="M1321.57 2672.82L1273.07 2640.87C1269.11 2638.26 1269.61 2633.82 1274.18 2630.71L1320.45 2600.24C1322.8 2598.75 1325.43 2597.91 1328.11 2597.78C1330.8 2597.66 1333.47 2598.26 1335.92 2599.54L1384.42 2631.28C1388.37 2633.89 1387.82 2638.33 1383.3 2641.43L1337.03 2671.91C1334.69 2673.42 1332.08 2674.29 1329.39 2674.45C1326.71 2674.61 1324.03 2674.05 1321.57 2672.82V2672.82Z" fill="#2B303F" />
                    <path id="Vector_23" d="M1389.43 2717.48L1340.93 2685.52C1336.97 2682.91 1337.47 2678.47 1342.04 2675.36L1388.31 2644.89C1390.67 2643.41 1393.29 2642.58 1395.97 2642.46C1398.66 2642.34 1401.33 2642.93 1403.78 2644.19L1452.27 2676.14C1456.23 2678.75 1455.74 2683.19 1451.16 2686.3L1404.89 2716.77C1402.54 2718.25 1399.91 2719.08 1397.23 2719.2C1394.55 2719.32 1391.87 2718.73 1389.43 2717.48V2717.48Z" fill="#2B303F" />
                    <path id="Vector_24" d="M1458.09 2762.69L1409.65 2730.74C1405.69 2728.13 1406.19 2723.68 1410.77 2720.58L1457.04 2690.11C1459.38 2688.61 1462.01 2687.77 1464.69 2687.65C1467.38 2687.53 1470.06 2688.13 1472.5 2689.4L1520.94 2721.28C1524.89 2723.89 1524.4 2728.34 1519.88 2731.44L1473.55 2761.91C1471.21 2763.43 1468.59 2764.29 1465.9 2764.42C1463.21 2764.56 1460.54 2763.96 1458.09 2762.69V2762.69Z" fill="#2B303F" />
                    <path id="Vector_25" d="M1526.75 2807.62L1478.25 2775.74C1474.29 2773.13 1474.79 2768.69 1479.37 2765.58L1525.64 2735.11C1527.97 2733.62 1530.58 2732.78 1533.26 2732.64C1535.93 2732.51 1538.6 2733.09 1541.04 2734.33L1589.54 2766.29C1593.5 2768.9 1593 2773.34 1588.42 2776.45L1542.15 2806.92C1539.82 2808.41 1537.2 2809.25 1534.53 2809.37C1531.85 2809.5 1529.19 2808.9 1526.75 2807.62V2807.62Z" fill="#2B303F" />
                    <path id="Vector_26" d="M1595.23 2853.05L1546.73 2821.17C1542.77 2818.56 1543.27 2814.11 1547.78 2810.94L1594.11 2780.54C1596.45 2779.03 1599.06 2778.18 1601.73 2778.04C1604.41 2777.91 1607.08 2778.5 1609.52 2779.76L1658.01 2811.71C1661.97 2814.32 1661.48 2818.77 1656.9 2821.87L1610.63 2852.34C1608.29 2853.82 1605.68 2854.65 1603 2854.78C1600.33 2854.9 1597.67 2854.31 1595.23 2853.05V2853.05Z" fill="#2B303F" />
                    <path id="Vector_27" d="M1664.01 2898.26L1615.52 2866.31C1611.56 2863.7 1612.05 2859.26 1616.63 2856.15L1662.9 2825.68C1665.25 2824.2 1667.88 2823.37 1670.56 2823.25C1673.24 2823.13 1675.92 2823.72 1678.36 2824.98L1726.86 2856.93C1730.82 2859.54 1730.33 2863.98 1725.75 2867.09L1679.48 2897.56C1677.12 2899.04 1674.5 2899.87 1671.82 2899.99C1669.13 2900.11 1666.46 2899.52 1664.01 2898.26V2898.26Z" fill="#2B303F" />
                    <path id="Vector_28" d="M1732.68 2943.48L1684.18 2911.53C1680.22 2908.92 1680.72 2904.47 1685.29 2901.37L1731.31 2871.11C1733.66 2869.61 1736.29 2868.77 1738.97 2868.65C1741.66 2868.53 1744.33 2869.13 1746.78 2870.4L1795.28 2902.29C1799.23 2904.9 1798.74 2909.34 1794.16 2912.44L1747.89 2942.91C1745.57 2944.34 1742.98 2945.13 1740.34 2945.23C1737.7 2945.33 1735.08 2944.73 1732.68 2943.48V2943.48Z" fill="#2B303F" />
                    <path id="Vector_29" d="M1801.34 2988.62L1752.84 2956.74C1748.88 2954.13 1749.38 2949.69 1753.95 2946.58L1800.22 2916.11C1802.56 2914.62 1805.17 2913.78 1807.85 2913.64C1810.52 2913.51 1813.18 2914.09 1815.63 2915.33L1864.12 2947.29C1868.08 2949.9 1867.59 2954.34 1863.07 2957.45L1816.74 2987.92C1814.4 2989.41 1811.79 2990.25 1809.11 2990.38C1806.44 2990.5 1803.77 2989.9 1801.34 2988.62Z" fill="#2B303F" />
                    <path id="Vector_30" d="M1870 3033.84L1821.5 3001.88C1817.54 2999.35 1818.04 2994.83 1822.62 2991.73L1868.89 2961.33C1871.22 2959.82 1873.83 2958.97 1876.51 2958.83C1879.18 2958.7 1881.85 2959.29 1884.29 2960.55L1932.79 2992.5C1936.75 2995.11 1936.25 2999.56 1931.67 3002.66L1885.4 3033.34C1883.05 3034.79 1880.43 3035.58 1877.75 3035.67C1875.08 3035.75 1872.42 3035.13 1870 3033.84V3033.84Z" fill="#2B303F" />
                    <path id="Vector_31" d="M1938.6 3079.05L1890.17 3047.45C1886.21 3044.84 1886.7 3040.4 1891.22 3037.3L1937.55 3006.82C1939.89 3005.35 1942.5 3004.51 1945.18 3004.39C1947.85 3004.27 1950.51 3004.86 1952.95 3006.12L2001.45 3038.07C2005.41 3040.61 2004.91 3045.12 2000.34 3048.23L1954 3078.35C1951.66 3079.81 1949.05 3080.64 1946.37 3080.76C1943.7 3080.89 1941.04 3080.3 1938.6 3079.05V3079.05Z" fill="#2B303F" />
                    <path id="Vector_32" d="M2007.26 3124.27L1958.77 3092.31C1954.81 3089.7 1955.3 3085.26 1959.88 3082.16L2006.15 3051.68C2008.5 3050.19 2011.12 3049.35 2013.81 3049.23C2016.49 3049.1 2019.17 3049.71 2021.61 3050.98L2070.11 3082.86C2074.07 3085.47 2073.57 3089.92 2069 3093.02L2022.73 3123.49C2020.38 3125 2017.76 3125.85 2015.08 3125.99C2012.39 3126.12 2009.71 3125.53 2007.26 3124.27V3124.27Z" fill="#2B303F" />
                    <path id="Vector_33" d="M2109.02 3191.21L2027.43 3137.53C2023.47 3134.92 2023.97 3130.48 2028.54 3127.37L2074.81 3096.9C2077.16 3095.4 2079.78 3094.54 2082.46 3094.41C2085.15 3094.27 2087.83 3094.86 2090.28 3096.12L2171.81 3149.87C2175.76 3152.48 2175.27 3156.93 2170.69 3160.03L2124.42 3190.5C2122.08 3191.98 2119.47 3192.81 2116.79 3192.94C2114.12 3193.06 2111.46 3192.47 2109.02 3191.21V3191.21Z" fill="#2B303F" />
                    <path id="Vector_34" d="M1218.76 2692.72L1170.26 2660.83C1166.3 2658.22 1166.8 2653.78 1171.38 2650.68L1217.89 2620.2C1220.24 2618.7 1222.86 2617.85 1225.54 2617.71C1228.23 2617.58 1230.91 2618.16 1233.36 2619.43L1281.85 2651.38C1285.81 2653.99 1285.32 2658.43 1280.74 2661.54L1234.47 2692.01C1232.09 2693.53 1229.42 2694.39 1226.69 2694.51C1223.96 2694.64 1221.24 2694.02 1218.76 2692.72Z" fill="#2B303F" />
                    <path id="Vector_35" d="M1287.55 2738L1239.05 2706.05C1235.09 2703.51 1235.58 2698.99 1240.16 2695.89L1286.43 2665.42C1288.79 2663.94 1291.41 2663.11 1294.09 2662.99C1296.78 2662.86 1299.45 2663.46 1301.9 2664.71L1350.39 2696.67C1354.35 2699.28 1353.8 2703.72 1349.28 2706.82L1303.01 2737.09C1300.67 2738.6 1298.06 2739.47 1295.37 2739.62C1292.69 2739.78 1290.01 2739.23 1287.55 2738V2738Z" fill="#2B303F" />
                    <path id="Vector_36" d="M1356.33 2783.29L1307.84 2751.19C1303.88 2748.58 1304.37 2744.14 1308.95 2741.04L1355.22 2710.56C1357.56 2709.09 1360.17 2708.25 1362.85 2708.13C1365.52 2708.01 1368.18 2708.6 1370.62 2709.86L1419.12 2741.81C1423.08 2744.35 1422.58 2748.86 1418 2751.97L1371.73 2782.44C1369.4 2783.94 1366.8 2784.8 1364.12 2784.94C1361.45 2785.09 1358.78 2784.52 1356.33 2783.29V2783.29Z" fill="#2B303F" />
                    <path id="Vector_37" d="M1425.06 2828.78L1376.56 2796.83C1372.66 2794.22 1373.16 2789.78 1377.67 2786.67L1423.94 2756.2C1426.3 2754.72 1428.92 2753.89 1431.6 2753.77C1434.29 2753.65 1436.96 2754.24 1439.41 2755.5L1487.9 2787.38C1491.86 2789.99 1491.37 2794.43 1486.79 2797.54L1440.58 2827.8C1438.25 2829.35 1435.62 2830.24 1432.92 2830.41C1430.22 2830.59 1427.53 2830.03 1425.06 2828.78V2828.78Z" fill="#2B303F" />
                    <path id="Vector_38" d="M1493.29 2873.43L1444.79 2841.55C1440.83 2838.94 1441.32 2834.5 1445.9 2831.39L1492.17 2800.92C1494.52 2799.43 1497.14 2798.59 1499.82 2798.45C1502.51 2798.32 1505.18 2798.9 1507.64 2800.15L1556.13 2832.1C1560.09 2834.71 1559.6 2839.15 1555.02 2842.26L1508.63 2872.73C1506.29 2874.2 1503.69 2875.03 1501.03 2875.15C1498.37 2875.27 1495.71 2874.69 1493.29 2873.43V2873.43Z" fill="#2B303F" />
                    <path id="Vector_39" d="M1562.07 2918.72L1513.57 2886.84C1509.62 2884.23 1510.11 2879.78 1514.69 2876.68L1560.96 2846.21C1563.3 2844.7 1565.92 2843.85 1568.61 2843.71C1571.3 2843.58 1573.97 2844.17 1576.42 2845.43L1624.86 2877.38C1628.82 2879.99 1628.32 2884.44 1623.81 2887.54L1577.47 2918.01C1575.14 2919.51 1572.52 2920.35 1569.85 2920.47C1567.17 2920.59 1564.51 2919.99 1562.07 2918.72V2918.72Z" fill="#2B303F" />
                    <path id="Vector_40" d="M1630.86 2964.01L1582.36 2932.12C1578.4 2929.51 1578.9 2925.07 1583.47 2921.89L1629.74 2891.49C1632.08 2889.99 1634.69 2889.14 1637.36 2889C1640.04 2888.87 1642.71 2889.45 1645.15 2890.72L1693.64 2922.67C1697.6 2925.28 1697.11 2929.72 1692.53 2932.83L1646.26 2963.3C1643.92 2964.78 1641.31 2965.61 1638.63 2965.73C1635.96 2965.85 1633.3 2965.26 1630.86 2964.01V2964.01Z" fill="#2B303F" />
                    <path id="Vector_41" d="M1699.58 3009.29L1650.9 2977.34C1646.94 2974.73 1647.44 2970.28 1652.01 2967.18L1698.28 2936.71C1700.64 2935.23 1703.26 2934.4 1705.94 2934.28C1708.63 2934.15 1711.3 2934.74 1713.75 2936L1762.24 2967.96C1766.2 2970.57 1765.71 2975.01 1761.13 2978.11L1714.86 3008.59C1712.53 3010.03 1709.94 3010.84 1707.29 3010.97C1704.64 3011.09 1702.01 3010.51 1699.58 3009.29V3009.29Z" fill="#2B303F" />
                    <path id="Vector_42" d="M1768.43 3054.51L1719.93 3022.55C1715.97 3019.94 1716.47 3015.5 1721.05 3012.4L1767.32 2981.92C1769.67 2980.45 1772.29 2979.61 1774.98 2979.49C1777.66 2979.37 1780.33 2979.96 1782.78 2981.22L1831.28 3013.1C1835.24 3015.71 1834.74 3020.15 1830.16 3023.33L1783.89 3053.73C1781.55 3055.23 1778.93 3056.09 1776.24 3056.22C1773.56 3056.36 1770.88 3055.77 1768.43 3054.51V3054.51Z" fill="#2B303F" />
                    <path id="Vector_43" d="M1837.15 3099.86L1788.66 3067.91C1784.7 3065.3 1785.19 3060.85 1789.77 3057.75L1836.04 3027.28C1838.38 3025.79 1840.99 3024.94 1843.67 3024.82C1846.34 3024.7 1849.01 3025.3 1851.44 3026.57L1899.94 3058.46C1903.9 3061.07 1903.4 3065.51 1898.83 3068.61L1852.56 3099.09C1850.22 3100.59 1847.61 3101.44 1844.94 3101.58C1842.26 3101.71 1839.59 3101.13 1837.15 3099.86V3099.86Z" fill="#2B303F" />
                    <path id="Vector_44" d="M1905.88 3145.08L1857.44 3113.19C1853.48 3110.58 1853.98 3106.14 1858.49 3103.04L1904.83 3072.56C1907.16 3071.07 1909.78 3070.23 1912.45 3070.11C1915.13 3069.98 1917.79 3070.58 1920.23 3071.86L1968.73 3103.74C1972.68 3106.35 1972.19 3110.8 1967.61 3113.9L1921.34 3144.37C1918.99 3145.86 1916.37 3146.71 1913.68 3146.83C1911 3146.95 1908.32 3146.35 1905.88 3145.08V3145.08Z" fill="#2B303F" />
                    <path id="Vector_45" d="M1974.66 3190.36L1926.17 3158.48C1922.21 3155.87 1922.7 3151.43 1927.28 3148.32L1973.55 3117.85C1975.89 3116.35 1978.52 3115.49 1981.2 3115.36C1983.89 3115.22 1986.56 3115.81 1989.02 3117.07L2037.51 3149.03C2041.47 3151.64 2040.98 3156.08 2036.4 3159.19L1990.13 3189.66C1987.78 3191.15 1985.16 3191.99 1982.47 3192.11C1979.78 3192.24 1977.11 3191.64 1974.66 3190.36V3190.36Z" fill="#2B303F" />
                    <path id="Vector_46" d="M1149.85 2647.43L1068.26 2593.75C1064.3 2591.14 1064.79 2586.7 1069.37 2583.59L1115.64 2553.12C1118 2551.64 1120.62 2550.81 1123.3 2550.69C1125.99 2550.57 1128.66 2551.16 1131.11 2552.42L1212.64 2606.1C1216.59 2608.71 1216.1 2613.15 1211.52 2616.25L1165.25 2646.73C1162.91 2648.22 1160.3 2649.06 1157.63 2649.18C1154.95 2649.3 1152.28 2648.7 1149.85 2647.43V2647.43Z" fill="#2B303F" />
                    <path id="Vector_47" d="M2105.68 3194.1L2057.18 3162.15C2054.96 3160.88 2052.5 3160.21 2050.01 3160.21C2047.52 3160.21 2045.06 3160.88 2042.83 3162.15L1993.9 3194.38C1993.04 3194.73 1992.3 3195.37 1991.77 3196.21C1991.25 3197.06 1990.96 3198.07 1990.96 3199.11C1990.96 3200.15 1991.25 3201.16 1991.77 3202.01C1992.3 3202.85 1993.04 3203.49 1993.9 3203.84L1997.74 3206.38C2001.39 3208.77 2001.39 3212.65 1997.74 3215.12L1946.09 3249.12C1945.2 3249.45 1944.44 3250.09 1943.89 3250.94C1943.35 3251.8 1943.06 3252.83 1943.06 3253.88C1943.06 3254.94 1943.35 3255.97 1943.89 3256.82C1944.44 3257.68 1945.2 3258.32 1946.09 3258.64L1977.01 3278.96C1979.24 3280.25 1981.7 3280.92 1984.19 3280.92C1986.68 3280.92 1989.14 3280.25 1991.37 3278.96L2039.74 3247.15L2049.76 3240.52L2056.94 3235.79L2064.17 3230.99L2105.86 3203.55C2106.72 3203.19 2107.45 3202.53 2107.96 3201.67C2108.47 3200.81 2108.73 3199.79 2108.71 3198.75C2108.69 3197.71 2108.39 3196.71 2107.85 3195.88C2107.3 3195.04 2106.55 3194.42 2105.68 3194.1V3194.1Z" fill="#2B303F" />
                    <path id="Vector_48" d="M917.635 2757.26L869.138 2725.38C865.179 2722.77 865.674 2718.32 870.252 2715.22L916.521 2684.75C918.859 2683.25 921.472 2682.41 924.149 2682.29C926.825 2682.17 929.489 2682.77 931.924 2684.04L980.421 2715.92C984.38 2718.53 983.885 2722.98 979.307 2726.08L933.347 2756.55C930.971 2758.1 928.302 2758.98 925.567 2759.1C922.831 2759.22 920.11 2758.59 917.635 2757.26V2757.26Z" fill="#2B303F" />
                    <path id="Vector_49" d="M986.05 2802.33L937.553 2770.45C933.594 2767.84 934.089 2763.4 938.667 2760.29L984.936 2730.03C987.28 2728.53 989.901 2727.67 992.587 2727.54C995.274 2727.4 997.951 2727.99 1000.4 2729.25L1048.9 2761.21C1052.86 2763.82 1052.36 2768.26 1047.78 2771.37L1001.39 2801.63C999.058 2803.1 996.457 2803.93 993.793 2804.05C991.13 2804.17 988.479 2803.58 986.05 2802.33V2802.33Z" fill="#2B303F" />
                    <path id="Vector_50" d="M1054.53 2847.41L1006.03 2815.52C1002.07 2812.91 1002.57 2808.47 1007.14 2805.37L1053.41 2774.89C1055.75 2773.39 1058.36 2772.54 1061.03 2772.4C1063.71 2772.27 1066.38 2772.85 1068.82 2774.12L1117.31 2806.07C1121.27 2808.68 1120.78 2813.12 1116.2 2816.23L1069.93 2846.7C1067.59 2848.19 1064.98 2849.03 1062.3 2849.16C1059.63 2849.28 1056.96 2848.68 1054.53 2847.41V2847.41Z" fill="#2B303F" />
                    <path id="Vector_51" d="M1637.48 3231.2L1589.04 3199.32C1585.08 3196.71 1585.58 3192.27 1590.15 3189.16L1636.42 3158.69C1638.77 3157.19 1641.39 3156.33 1644.08 3156.2C1646.76 3156.06 1649.44 3156.65 1651.89 3157.92L1700.39 3189.87C1704.28 3192.48 1703.79 3196.92 1699.27 3200.03L1652.94 3230.85C1650.57 3232.29 1647.93 3233.07 1645.25 3233.13C1642.56 3233.19 1639.9 3232.53 1637.48 3231.2V3231.2Z" fill="#2B303F" />
                    <path id="Vector_52" d="M1705.95 3276.28L1657.46 3244.39C1653.5 3241.79 1653.99 3237.34 1658.57 3234.24L1704.84 3203.76C1707.17 3202.26 1709.78 3201.41 1712.46 3201.27C1715.14 3201.14 1717.8 3201.73 1720.24 3202.99L1768.74 3234.94C1772.7 3237.55 1772.2 3242 1767.63 3245.1L1721.36 3275.57C1719.02 3277.06 1716.41 3277.91 1713.73 3278.03C1711.05 3278.15 1708.39 3277.55 1705.95 3276.28V3276.28Z" fill="#2B303F" />
                    <path id="Vector_53" d="M1774.62 3321.35L1726.12 3289.47C1722.16 3286.86 1722.65 3282.41 1727.23 3279.24L1773.5 3248.84C1775.85 3247.33 1778.47 3246.48 1781.15 3246.35C1783.84 3246.21 1786.52 3246.8 1788.97 3248.06L1837.46 3280.02C1841.42 3282.63 1840.93 3287.07 1836.35 3290.17L1790.08 3320.65C1787.73 3322.12 1785.1 3322.96 1782.42 3323.08C1779.74 3323.2 1777.06 3322.61 1774.62 3321.35V3321.35Z" fill="#2B303F" />
                    <path id="Vector_54" d="M1842.66 3366.43L1794.16 3334.47C1790.2 3331.93 1790.7 3327.42 1795.28 3324.31L1841.55 3293.84C1843.89 3292.37 1846.5 3291.53 1849.17 3291.41C1851.85 3291.29 1854.51 3291.88 1856.95 3293.14L1905.45 3325.09C1909.4 3327.7 1908.91 3332.14 1904.39 3335.25L1858.06 3365.72C1855.72 3367.2 1853.11 3368.03 1850.43 3368.15C1847.76 3368.27 1845.1 3367.68 1842.66 3366.43V3366.43Z" fill="#2B303F" />
                    <path id="Vector_55" d="M1140.63 2904.12L1074.44 2860.6C1070.55 2857.99 1071.04 2853.54 1075.56 2850.44L1121.83 2819.97C1124.17 2818.46 1126.79 2817.61 1129.48 2817.47C1132.17 2817.34 1134.84 2817.93 1137.29 2819.19L1203.42 2862.71C1207.38 2865.32 1206.88 2869.77 1202.31 2872.94L1156.04 2903.34C1153.7 2904.85 1151.09 2905.7 1148.42 2905.84C1145.74 2905.97 1143.07 2905.38 1140.63 2904.12V2904.12Z" fill="#2B303F" />
                    <path id="Vector_56" d="M1569 3186.13L1502.87 3142.61C1498.91 3140 1499.41 3135.55 1503.99 3132.45L1550.26 3101.98C1552.61 3100.5 1555.23 3099.67 1557.92 3099.55C1560.6 3099.42 1563.27 3100.02 1565.72 3101.27L1631.85 3144.8C1635.81 3147.41 1635.31 3151.85 1630.73 3154.95L1584.46 3185.43C1582.12 3186.92 1579.49 3187.76 1576.81 3187.88C1574.12 3188 1571.44 3187.4 1569 3186.13V3186.13Z" fill="#2B303F" />
                    <path id="Vector_57" d="M1482.96 3129.49L1160.55 2917.24C1156.59 2914.63 1157.09 2910.19 1161.66 2907.08L1207.93 2876.61C1210.28 2875.12 1212.91 2874.27 1215.59 2874.15C1218.28 2874.03 1220.95 2874.63 1223.4 2875.9L1545.74 3088.15C1549.7 3090.76 1549.2 3095.21 1544.63 3098.31L1498.36 3128.78C1496.02 3130.26 1493.4 3131.09 1490.73 3131.21C1488.06 3131.34 1485.39 3130.75 1482.96 3129.49Z" fill="#2B303F" />
                    <path id="Vector_58" d="M1170.94 2748.86L1122.45 2716.91C1118.49 2714.3 1118.98 2709.86 1123.56 2706.75L1169.83 2676.28C1172.17 2674.79 1174.78 2673.95 1177.46 2673.82C1180.13 2673.7 1182.8 2674.3 1185.23 2675.58L1233.73 2707.46C1237.69 2710.07 1237.19 2714.51 1232.62 2717.62L1186.35 2748.09C1184.01 2749.59 1181.4 2750.45 1178.73 2750.58C1176.05 2750.72 1173.38 2750.13 1170.94 2748.86V2748.86Z" fill="#2B303F" />
                    <path id="Vector_59" d="M1239.48 2794.01L1190.99 2762.06C1187.03 2759.45 1187.52 2755 1192.1 2751.9L1238.37 2721.43C1240.72 2719.93 1243.34 2719.09 1246.03 2718.97C1248.71 2718.85 1251.39 2719.45 1253.83 2720.72L1302.33 2752.6C1306.29 2755.21 1305.79 2759.66 1301.22 2762.76L1255.01 2793.52C1252.64 2794.99 1250 2795.8 1247.3 2795.88C1244.6 2795.97 1241.92 2795.33 1239.48 2794.01V2794.01Z" fill="#2B303F" />
                    <path id="Vector_60" d="M1308.08 2839.15L1259.59 2807.2C1255.63 2804.59 1256.12 2800.15 1260.7 2797.04L1306.97 2766.57C1309.31 2765.08 1311.92 2764.24 1314.6 2764.11C1317.27 2763.99 1319.94 2764.59 1322.37 2765.86L1370.87 2797.75C1374.83 2800.36 1374.33 2804.8 1369.76 2807.91L1323.49 2838.38C1321.15 2839.88 1318.54 2840.73 1315.87 2840.87C1313.19 2841 1310.52 2840.42 1308.08 2839.15V2839.15Z" fill="#2B303F" />
                    <path id="Vector_61" d="M1376.62 2884.3L1328.12 2852.34C1324.17 2849.73 1324.66 2845.29 1329.24 2842.19L1375.51 2811.71C1377.86 2810.22 1380.48 2809.38 1383.17 2809.26C1385.85 2809.13 1388.53 2809.73 1390.97 2811.01L1439.47 2842.89C1443.43 2845.5 1442.93 2849.95 1438.36 2853.05L1392.09 2883.52C1389.74 2885.03 1387.12 2885.88 1384.43 2886.01C1381.75 2886.15 1379.07 2885.56 1376.62 2884.3Z" fill="#2B303F" />
                    <path id="Vector_62" d="M1445.22 2929.44L1396.73 2897.49C1392.77 2894.88 1393.26 2890.43 1397.84 2887.33L1444.11 2856.86C1446.45 2855.37 1449.06 2854.52 1451.74 2854.4C1454.41 2854.28 1457.08 2854.88 1459.51 2856.15L1508.01 2888.04C1511.97 2890.65 1511.47 2895.09 1506.89 2898.19L1460.62 2928.67C1458.29 2930.17 1455.68 2931.02 1453 2931.16C1450.33 2931.29 1447.66 2930.71 1445.22 2929.44V2929.44Z" fill="#2B303F" />
                    <path id="Vector_63" d="M1513.76 2974.59L1465.33 2942.63C1461.37 2940.02 1461.86 2935.58 1466.44 2932.48L1512.71 2902C1515.06 2900.51 1517.68 2899.67 1520.37 2899.55C1523.05 2899.42 1525.73 2900.02 1528.17 2901.3L1576.67 2933.18C1580.63 2935.79 1580.13 2940.23 1575.56 2943.34L1529.29 2973.81C1526.94 2975.33 1524.3 2976.19 1521.61 2976.32C1518.91 2976.46 1516.22 2975.86 1513.76 2974.59V2974.59Z" fill="#2B303F" />
                    <path id="Vector_64" d="M1582.36 3019.73L1533.86 2987.78C1529.91 2985.17 1530.4 2980.72 1534.98 2977.62L1581.25 2947.15C1583.58 2945.66 1586.2 2944.81 1588.87 2944.69C1591.55 2944.57 1594.22 2945.17 1596.65 2946.44L1645.15 2978.33C1649.11 2980.94 1648.61 2985.38 1644.1 2988.48L1597.76 3019.24C1595.41 3020.7 1592.79 3021.5 1590.11 3021.59C1587.44 3021.67 1584.78 3021.04 1582.36 3019.73Z" fill="#2B303F" />
                    <path id="Vector_65" d="M1650.9 3064.88L1602.46 3032.92C1598.51 3030.31 1599 3025.87 1603.52 3022.76L1649.85 2992.29C1652.19 2990.8 1654.8 2989.96 1657.48 2989.83C1660.15 2989.71 1662.82 2990.31 1665.25 2991.59L1713.75 3023.47C1717.71 3026.08 1717.21 3030.52 1712.63 3033.63L1666.36 3064.1C1664.02 3065.6 1661.4 3066.46 1658.71 3066.59C1656.03 3066.73 1653.35 3066.14 1650.9 3064.88V3064.88Z" fill="#2B303F" />
                    <path id="Vector_66" d="M1719.5 3110.02L1671 3078.07C1667.04 3075.46 1667.54 3071.01 1672.12 3067.91L1718.39 3037.44C1720.74 3035.96 1723.36 3035.13 1726.05 3035C1728.73 3034.88 1731.4 3035.47 1733.85 3036.73L1782.35 3068.61C1786.24 3071.22 1785.75 3075.67 1781.23 3078.77L1734.96 3109.24C1732.62 3110.75 1730 3111.6 1727.31 3111.74C1724.63 3111.87 1721.95 3111.28 1719.5 3110.02V3110.02Z" fill="#2B303F" />
                    <path id="Vector_67" d="M1788.1 3155.16L1739.6 3123.21C1735.65 3120.6 1736.14 3116.16 1740.72 3113.05L1786.99 3082.72C1789.33 3081.25 1791.94 3080.41 1794.62 3080.29C1797.29 3080.17 1799.95 3080.76 1802.39 3082.02L1850.89 3113.9C1854.85 3116.51 1854.35 3120.95 1849.77 3124.13L1803.5 3154.53C1801.16 3156.01 1798.55 3156.84 1795.87 3156.95C1793.19 3157.06 1790.53 3156.45 1788.1 3155.16V3155.16Z" fill="#2B303F" />
                    <path id="Vector_68" d="M1856.64 3200.31L1808.14 3168.36C1804.18 3165.75 1804.68 3161.3 1809.26 3158.2L1855.53 3127.73C1857.88 3126.25 1860.5 3125.42 1863.19 3125.29C1865.87 3125.17 1868.54 3125.76 1870.99 3127.02L1919.49 3158.9C1923.45 3161.51 1922.95 3165.96 1918.37 3169.13L1872.1 3199.53C1869.76 3201.04 1867.14 3201.89 1864.45 3202.03C1861.77 3202.16 1859.09 3201.57 1856.64 3200.31V3200.31Z" fill="#2B303F" />
                    <path id="Vector_69" d="M1925.24 3245.45L1876.74 3213.5C1872.78 3210.89 1873.28 3206.45 1877.86 3203.34L1924.13 3172.87C1926.47 3171.39 1929.08 3170.56 1931.75 3170.44C1934.43 3170.32 1937.09 3170.91 1939.53 3172.16L1988.03 3204.05C1991.98 3206.66 1991.49 3211.1 1986.91 3214.28L1940.64 3244.96C1938.29 3246.42 1935.67 3247.22 1932.99 3247.31C1930.32 3247.4 1927.66 3246.76 1925.24 3245.45V3245.45Z" fill="#2B303F" />
                    <path id="Vector_70" d="M1102.34 2703.72L1002.07 2637.63C998.112 2635.09 998.607 2630.57 1003.18 2627.47L1049.45 2597C1051.8 2595.52 1054.41 2594.69 1057.08 2594.57C1059.76 2594.44 1062.42 2595.03 1064.86 2596.29L1165.19 2662.31C1169.15 2664.92 1168.65 2669.37 1164.08 2672.47L1117.81 2702.94C1115.46 2704.43 1112.84 2705.28 1110.16 2705.41C1107.47 2705.55 1104.8 2704.97 1102.34 2703.72V2703.72Z" fill="#2B303F" />
                    <path id="Vector_71" d="M1070.36 2770.24L1021.87 2738.28C1017.91 2735.67 1018.4 2731.23 1022.98 2728.13L1069.43 2697.65C1071.78 2696.18 1074.39 2695.35 1077.06 2695.22C1079.74 2695.1 1082.4 2695.69 1084.84 2696.95L1133.33 2728.9C1137.29 2731.51 1136.8 2735.96 1132.22 2739.06L1085.95 2769.53C1083.58 2771.04 1080.94 2771.89 1078.23 2772.02C1075.52 2772.14 1072.83 2771.53 1070.36 2770.24V2770.24Z" fill="#2B303F" />
                    <path id="Vector_72" d="M1138.9 2815.38L1090.4 2783.43C1086.45 2780.82 1086.94 2776.37 1091.52 2773.27L1137.79 2742.8C1140.14 2741.32 1142.76 2740.49 1145.45 2740.37C1148.13 2740.24 1150.8 2740.83 1153.25 2742.09L1201.75 2774.05C1205.71 2776.59 1205.15 2781.1 1200.64 2784.2L1154.37 2814.68C1152.02 2816.17 1149.39 2817.01 1146.71 2817.13C1144.02 2817.26 1141.35 2816.66 1138.9 2815.38V2815.38Z" fill="#2B303F" />
                    <path id="Vector_73" d="M1207.44 2860.53L1158.94 2828.78C1154.98 2826.17 1155.48 2821.73 1160.06 2818.63L1206.33 2788.15C1208.68 2786.68 1211.3 2785.85 1213.99 2785.72C1216.67 2785.6 1219.34 2786.19 1221.79 2787.45L1270.29 2819.33C1274.25 2821.94 1273.75 2826.39 1269.17 2829.49L1222.9 2859.96C1220.55 2861.43 1217.92 2862.25 1215.23 2862.35C1212.54 2862.45 1209.88 2861.82 1207.44 2860.53Z" fill="#2B303F" />
                    <path id="Vector_74" d="M1276.04 2905.67L1227.54 2873.72C1223.58 2871.11 1224.08 2866.66 1228.66 2863.56L1274.93 2833.09C1277.26 2831.59 1279.88 2830.75 1282.55 2830.63C1285.23 2830.51 1287.89 2831.11 1290.33 2832.38L1338.83 2864.05C1342.78 2866.66 1342.29 2871.11 1337.71 2874.21L1291.44 2904.68C1289.13 2906.22 1286.52 2907.11 1283.85 2907.28C1281.17 2907.45 1278.49 2906.9 1276.04 2905.67Z" fill="#2B303F" />
                    <path id="Vector_75" d="M1344.58 2950.82L1296.08 2918.86C1292.12 2916.25 1292.62 2911.81 1297.2 2908.7L1343.47 2878.16C1345.81 2876.67 1348.44 2875.83 1351.12 2875.7C1353.81 2875.58 1356.48 2876.18 1358.93 2877.46L1407.43 2909.34C1411.39 2911.95 1410.89 2916.39 1406.31 2919.5L1360.17 2950.04C1357.8 2951.55 1355.16 2952.41 1352.45 2952.54C1349.75 2952.68 1347.05 2952.09 1344.58 2950.82V2950.82Z" fill="#2B303F" />
                    <path id="Vector_76" d="M1413.12 2995.89L1364.68 2964.01C1360.72 2961.4 1361.22 2956.95 1365.73 2953.85L1412.07 2923.38C1414.4 2921.88 1417.02 2921.04 1419.69 2920.92C1422.37 2920.8 1425.03 2921.4 1427.47 2922.67L1475.97 2954.55C1479.92 2957.16 1479.43 2961.61 1474.85 2964.71L1428.58 2995.18C1426.23 2996.68 1423.61 2997.52 1420.92 2997.64C1418.24 2997.76 1415.56 2997.16 1413.12 2995.89V2995.89Z" fill="#2B303F" />
                    <path id="Vector_77" d="M1481.72 3041.03L1433.22 3009.15C1429.26 3006.54 1429.76 3002.1 1434.33 2998.99L1480.6 2968.52C1482.94 2967.03 1485.55 2966.19 1488.23 2966.05C1490.9 2965.92 1493.56 2966.5 1496.01 2967.74L1544.5 2999.7C1548.46 3002.31 1547.97 3006.75 1543.39 3009.86L1497.12 3040.4C1494.78 3041.88 1492.16 3042.71 1489.49 3042.82C1486.81 3042.93 1484.15 3042.32 1481.72 3041.03V3041.03Z" fill="#2B303F" />
                    <path id="Vector_78" d="M1550.26 3086.18L1501.76 3054.51C1497.8 3051.9 1498.3 3047.45 1502.87 3044.35L1549.14 3013.88C1551.49 3012.37 1554.11 3011.52 1556.79 3011.38C1559.48 3011.25 1562.16 3011.84 1564.61 3013.1L1613.1 3045.05C1617.06 3047.66 1616.57 3052.11 1611.99 3055.21L1565.72 3085.68C1563.36 3087.14 1560.73 3087.95 1558.04 3088.03C1555.35 3088.12 1552.69 3087.48 1550.26 3086.18V3086.18Z" fill="#2B303F" />
                    <path id="Vector_79" d="M1618.86 3131.32L1570.48 3099.44C1566.53 3096.83 1567.02 3092.39 1571.6 3089.28L1617.87 3058.81C1620.2 3057.31 1622.81 3056.45 1625.49 3056.32C1628.16 3056.18 1630.83 3056.77 1633.27 3058.03L1681.83 3089.78C1685.79 3092.39 1685.29 3096.83 1680.72 3099.93L1634.45 3130.41C1632.1 3131.97 1629.46 3132.86 1626.75 3133.02C1624.04 3133.18 1621.33 3132.6 1618.86 3131.32V3131.32Z" fill="#2B303F" />
                    <path id="Vector_80" d="M1687.4 3176.47L1638.9 3144.58C1634.94 3141.97 1635.43 3137.53 1640.01 3134.36L1686.28 3103.95C1688.63 3102.45 1691.25 3101.6 1693.93 3101.46C1696.62 3101.33 1699.3 3101.91 1701.75 3103.18L1750.18 3135.13C1754.14 3137.74 1753.65 3142.19 1749.13 3145.29L1702.8 3175.76C1700.46 3177.24 1697.84 3178.07 1695.17 3178.19C1692.5 3178.32 1689.83 3177.72 1687.4 3176.47V3176.47Z" fill="#2B303F" />
                    <path id="Vector_81" d="M1756.06 3221.61L1707.56 3189.66C1703.6 3187.12 1704.1 3182.6 1708.67 3179.5L1754.94 3149.03C1757.3 3147.55 1759.92 3146.72 1762.6 3146.6C1765.29 3146.47 1767.96 3147.06 1770.41 3148.32L1818.91 3180.28C1822.86 3182.89 1822.37 3187.33 1817.79 3190.43L1771.52 3220.91C1769.17 3222.38 1766.55 3223.22 1763.86 3223.34C1761.18 3223.46 1758.51 3222.87 1756.06 3221.61V3221.61Z" fill="#2B303F" />
                    <path id="Vector_82" d="M1909.4 3322.55L1776.04 3234.73C1772.08 3232.12 1772.57 3227.68 1777.15 3224.57L1823.42 3194.1C1825.76 3192.63 1828.38 3191.79 1831.05 3191.67C1833.72 3191.55 1836.39 3192.14 1838.82 3193.4L1972.19 3281.22C1976.15 3283.83 1975.65 3288.27 1971.08 3291.37L1924.81 3321.85C1922.46 3323.32 1919.85 3324.15 1917.18 3324.28C1914.5 3324.4 1911.84 3323.81 1909.4 3322.55V3322.55Z" fill="#2B303F" />
                    <path id="Vector_83" d="M1001.76 2725.09L935.635 2681.57C931.677 2678.96 932.171 2674.52 936.749 2671.41L982.833 2640.94C985.181 2639.45 987.801 2638.61 990.485 2638.47C993.169 2638.34 995.844 2638.92 998.298 2640.16L1064.42 2683.76C1068.38 2686.37 1067.89 2690.81 1063.31 2693.91L1017.04 2724.39C1014.71 2725.83 1012.12 2726.64 1009.47 2726.77C1006.82 2726.89 1004.19 2726.32 1001.76 2725.09V2725.09Z" fill="#2B303F" />
                    <path id="Vector_84" d="M2185.61 3146.26C2183.22 3146.43 2180.83 3145.96 2178.61 3144.9L1204.99 2503.55C1204.99 2503.55 1204.99 2503.55 1204.99 2503.55C1203.38 2502.49 1202.88 2501.24 1203.01 2500.07C1203.14 2498.83 1204.05 2497.29 1206.11 2495.89L1229.22 2480.67C1231.35 2479.32 1233.7 2478.56 1236.11 2478.44C1238.48 2478.32 1240.86 2478.83 1243.06 2479.93L2216.69 3121.01C2218.3 3122.06 2218.8 3123.32 2218.68 3124.49C2218.55 3125.74 2217.65 3127.27 2215.63 3128.67C2215.63 3128.67 2215.62 3128.67 2215.62 3128.68L2192.51 3143.9C2190.39 3145.29 2188.02 3146.1 2185.61 3146.26Z" fill="#E8E8E8" stroke="black" strokeWidth="3" />
                    <path id="Vector_85" opacity="0.4" d="M2955.79 3527.11C3040.35 3527.11 3108.89 3481.98 3108.89 3426.31C3108.89 3370.64 3040.35 3325.51 2955.79 3325.51C2871.24 3325.51 2802.7 3370.64 2802.7 3426.31C2802.7 3481.98 2871.24 3527.11 2955.79 3527.11Z" fill="black" />
                    <path id="Vector_86" d="M3123.86 3411.99V3143.1L2818.35 3151.92L2818.96 3399.72C2813.27 3429.56 2827.81 3460.74 2862.57 3483.66C2922.33 3523.02 3019.26 3523.02 3079.08 3483.66C3109.14 3463.63 3124.05 3437.88 3123.86 3411.99Z" fill="#08a280" />
                    <path id="Vector_87" d="M2970.83 3243.9C3055.38 3243.9 3123.92 3198.77 3123.92 3143.1C3123.92 3087.43 3055.38 3042.3 2970.83 3042.3C2886.27 3042.3 2817.73 3087.43 2817.73 3143.1C2817.73 3198.77 2886.27 3243.9 2970.83 3243.9Z" fill="#08a280" />
                    <path id="Vector_88" d="M3099.68 3143.1C3099.68 3159.54 3087.74 3176.75 3066.95 3190.43C3040.38 3206.46 3010.9 3215.23 2980.85 3216.04C2977.55 3216.04 2974.27 3216.04 2971.01 3216.04C2934.82 3216.04 2899.81 3206.66 2874.88 3190.22C2854.1 3176.54 2842.16 3159.33 2842.16 3142.89C2842.16 3126.46 2854.1 3109.24 2874.88 3095.56C2899.63 3079.12 2934.82 3069.74 2971.01 3069.74C2974.29 3069.74 2977.57 3069.74 2980.85 3069.74C3010.96 3070.46 3040.51 3079.21 3067.14 3095.28C3087.74 3109.46 3099.68 3126.74 3099.68 3143.1Z" fill="#6F4E37" />
                    <path id="Vector_89" d="M3101.53 3267.6L3068.99 3246.44L3053.22 3256.88L3068.99 3267.32V3274.37L3099.49 3294.41L3098.56 3300.83C3103.02 3305.13 3118.73 3338.28 3118.73 3338.28L3119.9 3337.15V3423.7C3119.9 3446.42 3108.21 3438.73 3103.2 3435.41L3068.99 3412.91L3053.22 3423.35L3068.99 3433.79V3440.84L3103.2 3463.35C3106.71 3465.7 3110.45 3467.59 3114.34 3468.99L3112.29 3479.85L3129.99 3468.49C3137.41 3463.49 3140.44 3451.57 3140.44 3438.09V3346.6C3140.93 3308.44 3115.14 3278.18 3101.53 3267.6Z" fill="black" />
                    <path id="Vector_90" d="M3087.43 3473.08L3053.22 3450.58V3422.86L3087.43 3445.43C3092.38 3448.67 3104.07 3456.36 3104.07 3433.65V3343.22C3104.07 3322.06 3087.12 3307.1 3084.27 3304.78L3053.34 3284.32V3256.6L3085.82 3277.76C3099.49 3288.34 3125.22 3318.46 3125.22 3356.76V3447.26C3125.1 3471.88 3115.2 3491.42 3087.43 3473.08Z" fill="black" />
                    <path id="Vector_91" opacity="0.4" d="M910.521 2131.16C976.149 2131.16 1029.35 2096.14 1029.35 2052.94C1029.35 2009.73 976.149 1974.71 910.521 1974.71C844.894 1974.71 791.692 2009.73 791.692 2052.94C791.692 2096.14 844.894 2131.16 910.521 2131.16Z" fill="black" />
                    <path id="Vector_92" d="M927.47 2116.63C993.098 2116.63 1046.3 2081.61 1046.3 2038.4C1046.3 1995.2 993.098 1960.18 927.47 1960.18C861.843 1960.18 808.641 1995.2 808.641 2038.4C808.641 2081.61 861.843 2116.63 927.47 2116.63Z" fill="#D6D8E5" />
                    <path id="Vector_93" d="M768.433 1770.92L810.002 2043.91H1045.99L1086.57 1772.9L768.433 1770.92Z" fill="#D6D8E5" />
                    <path id="Vector_94" d="M927.47 1872.36C1015.41 1872.36 1086.69 1825.43 1086.69 1767.54C1086.69 1709.65 1015.41 1662.72 927.47 1662.72C839.534 1662.72 768.248 1709.65 768.248 1767.54C768.248 1825.43 839.534 1872.36 927.47 1872.36Z" fill="#E4E6EF" />
                    <path id="Vector_95" d="M927.47 1849.29C888.438 1849.29 852.065 1839.49 825.033 1821.64C801.837 1806.4 788.537 1786.37 788.537 1767.54C788.537 1748.7 801.837 1728.74 825.033 1713.43C852.065 1695.66 888.438 1685.85 927.47 1685.85C966.503 1685.85 1002.88 1695.66 1029.91 1713.43C1053.1 1728.74 1066.4 1748.7 1066.4 1767.54C1066.4 1786.37 1053.1 1806.4 1029.91 1821.64C1002.88 1839.49 966.503 1849.29 927.47 1849.29Z" fill="#6F4E37" />
                    <g id="plant">
                        <path id="center_leaf" d="M996.009 1685.01C998.267 1649.73 993.346 1614.33 981.629 1581.57C969.911 1548.82 951.715 1519.59 928.46 1496.18V1496.18L927.965 1495.68V1496.67C904.694 1520.05 886.477 1549.24 874.737 1581.97C862.998 1614.7 858.054 1650.09 860.292 1685.36L769.299 1675.56C807.713 1840.69 928.46 1826.44 928.46 1826.44C937.553 1827.21 1050.13 1833.84 1086.94 1675.41L996.009 1685.01Z" fill="#08a280" />
                        <path id="left_leaf" d="M928.46 1826.44C928.46 1826.44 920.295 1697.92 768.928 1611.3C768.928 1611.3 733.051 1824.89 928.46 1826.44Z" fill="#08a280" />
                        <path id="right_leaf" d="M927.47 1826.44C927.47 1826.44 935.635 1697.92 1087 1611.3C1087 1611.3 1122.88 1824.89 927.47 1826.44Z" fill="#08a280" />
                    </g>
                    <path id="smoke" d="M2924.85 2996.81C2924.85 2982.97 2928.73 2970.61 2936.35 2959.72C2943.98 2948.83 2953.68 2941.91 2965.44 2938.97C2968.68 2922.48 2976.05 2908.8 2987.68 2898.2C2999.32 2887.6 3012.5 2882.3 3027.5 2882.3C3041.98 2882.3 3055.04 2887.45 3066.55 2897.76C3078.05 2908.06 3085.42 2921.31 3088.91 2937.35H3092.41C3106.89 2937.35 3119.3 2943.09 3129.64 2954.71C3139.85 2966.19 3145.03 2980.32 3145.03 2996.66C3145.03 3005.49 3143.47 3013.88 3140.24 3021.68C3137.01 3029.48 3132.61 3036.25 3126.93 3041.84V3042.29C3126.93 3050.97 3124.47 3058.77 3119.69 3065.69C3114.9 3072.6 3108.82 3076.87 3101.58 3078.79C3099.77 3087.91 3095.77 3095.71 3089.82 3102.04C3083.87 3108.37 3076.76 3112.34 3068.75 3113.81C3072.49 3118.82 3074.3 3124.41 3074.3 3130.44C3074.3 3137.51 3072.11 3143.54 3067.71 3148.69C3063.32 3153.7 3058.01 3156.35 3051.81 3156.35C3045.6 3156.35 3040.3 3153.85 3035.91 3148.69C3031.51 3143.69 3029.31 3137.51 3029.31 3130.44C3029.31 3127.65 3029.7 3124.85 3030.61 3122.06H3029.31C3021.81 3122.06 3015.35 3118.96 3009.92 3112.78C3004.49 3106.6 3001.77 3099.24 3001.77 3090.71C3001.77 3084.82 3003.07 3079.52 3005.65 3074.96C2998.93 3070.69 2994.15 3064.66 2991.17 3056.56H2974.62V3056.41C2960.79 3055.38 2949.02 3049.2 2939.33 3037.72C2929.63 3026.24 2924.85 3012.7 2924.85 2996.81Z" fill="white" />
                    <g id="loading_bar" clipPath="url(#clip0_359_11)">
                        <path id="Vector_96" d="M2993.29 1743.25C2987.04 1755.37 2978.75 1765.96 2968.91 1774.42C2959.06 1782.88 2947.85 1789.05 2935.92 1792.57C2923.98 1796.09 2911.56 1796.89 2899.35 1794.93C2887.14 1792.97 2875.4 1788.29 2864.78 1781.15L1892.38 1127.86C1870.93 1113.45 1855.38 1089.92 1849.15 1062.43C1842.92 1034.95 1846.52 1005.77 1859.16 981.308" fill="white" />
                        <path id="Vector_97" d="M1859.08 981.254C1871.72 956.794 1892.35 939.06 1916.46 931.953C1940.56 924.846 1966.14 928.948 1987.59 943.357L2959.99 1596.64C2981.44 1611.05 2996.99 1634.59 3003.22 1662.07C3009.45 1689.56 3005.85 1718.74 2993.21 1743.2" fill="white" />
                        <path id="Vector_98" d="M2999.15 1747.19C2985.6 1773.42 2963.46 1792.44 2937.62 1800.06C2911.77 1807.69 2884.33 1803.29 2861.32 1787.83L1888.93 1134.55C1865.92 1119.09 1849.25 1093.85 1842.57 1064.38C1835.89 1034.9 1839.75 1003.6 1853.3 977.37" fill="#08a280" />
                        <path id="Vector_99" d="M1853.24 977.335C1866.8 951.102 1888.93 932.083 1914.78 924.461C1940.63 916.838 1968.07 921.238 1991.07 936.691L2963.47 1589.98C2986.47 1605.43 3003.15 1630.67 3009.83 1660.15C3016.51 1689.63 3012.65 1720.92 2999.1 1747.15" fill="#08a280" />
                        <path id="Vector_100" d="M2987.46 1739.33C2999.15 1716.63 3002.48 1689.57 2996.7 1664.08C2990.91 1638.59 2976.5 1616.76 2956.62 1603.38L1984.22 950.094C1974.36 943.183 1963.39 938.583 1951.95 936.562C1940.51 934.542 1928.84 935.141 1917.61 938.326C1906.37 941.511 1895.81 947.216 1886.54 955.11C1877.26 963.003 1869.46 972.926 1863.58 984.299C1857.71 995.671 1853.88 1008.26 1852.32 1021.34C1850.76 1034.42 1851.5 1047.72 1854.5 1060.47C1857.51 1073.22 1862.71 1085.15 1869.8 1095.58C1876.89 1106.01 1885.74 1114.72 1895.82 1121.21L2868.22 1774.5C2888.12 1787.84 2911.86 1791.63 2934.21 1785.03C2956.56 1778.44 2975.71 1762.01 2987.46 1739.33" fill="white" />
                        <path id="Vector_101" d="M2827.65 1518.8C2825.97 1519.52 2824.17 1519.8 2822.38 1519.62C2808.28 1519.51 2793.77 1519.21 2780.08 1514.94C2769.9 1511.47 2760.08 1506.74 2750.8 1500.84C2730.26 1488.44 2710.41 1474.61 2691.37 1459.42C2655.3 1430.63 2622.28 1397.1 2586.1 1368.51C2550.23 1340.37 2511.53 1317.16 2475.43 1289.31C2470.8 1285.72 2465.58 1280.96 2465.75 1274.73C2465.93 1272.59 2466.55 1270.52 2467.55 1268.69C2468.55 1266.86 2469.91 1265.32 2471.52 1264.19C2482.76 1255.59 2497.66 1263.06 2509.46 1270.51C2592.08 1322.62 2674.75 1374.81 2754.43 1432.54C2768.41 1442.7 2782.42 1453.12 2794.19 1466.4C2802.96 1476.2 2812.22 1485.4 2821.93 1493.96C2827.08 1498.26 2837.8 1513.42 2827.65 1518.8Z" fill="url(#paint1_linear_359_11)" />
                        <path id="Vector_102" d="M2720.45 1463.98L1975.02 963.173C1940.78 940.169 1896.67 953.171 1876.5 992.212C1856.33 1031.25 1867.74 1081.55 1901.98 1104.55L2647.41 1605.36C2681.65 1628.36 2725.76 1615.36 2745.93 1576.32C2766.1 1537.28 2754.69 1486.98 2720.45 1463.98Z" fill="#08a280" />
                        <path id="Vector_103" d="M2365.29 1244.83C2360.39 1241.54 2354.89 1239.6 2349.21 1239.16C2345.47 1239.52 2341.84 1240.82 2338.58 1242.97C2335.33 1245.12 2332.54 1248.06 2330.4 1251.59C2326.18 1258.67 2322.46 1266.13 2319.3 1273.89C2308.91 1295.86 2293.05 1313.67 2277.46 1331.17C2264.7 1345.46 2250.47 1360.53 2232.68 1362.42C2231.07 1362.82 2229.39 1362.74 2227.82 1362.19C2226.24 1361.63 2224.82 1360.62 2223.69 1359.25C2221.76 1356.14 2222.83 1352.01 2223.96 1348.53C2242.84 1290.88 2286.76 1248.96 2314.15 1195.94C2319.54 1185.51 2325.83 1173.34 2336.38 1171.83C2342.9 1170.92 2349.17 1174.49 2354.99 1177.95L2443.76 1231.09C2457.66 1239.42 2476.32 1248.93 2485.8 1263.89C2497.64 1282.71 2468.71 1279.02 2457.82 1278.17C2425.3 1275.74 2394.33 1260.99 2365.29 1244.83Z" fill="url(#paint2_linear_359_11)" />
                        <path id="Vector_104" d="M2499.75 1239.84C2491.69 1251.26 2481.81 1262.23 2469.21 1265.1C2459.68 1267.3 2449.82 1264.52 2440.52 1261.22C2407.6 1249.49 2376.75 1231.23 2349.4 1207.27C2345.52 1203.89 2340.51 1200.16 2336.13 1202.39C2334.1 1203.8 2332.43 1205.8 2331.3 1208.19C2302.77 1257.54 2272.05 1305.2 2239.26 1350.98C2233.8 1358.53 2228.05 1366.3 2220.2 1370.47C2202.28 1379.79 2182.04 1366.55 2165.8 1353.87C2163.67 1352.61 2162.04 1350.49 2161.22 1347.92C2160.12 1342.1 2166.84 1339.02 2171.88 1337.56C2193.03 1331.58 2210.27 1313.49 2222.56 1292.92C2234.85 1272.35 2243.04 1249.02 2252.52 1226.56C2271.46 1181.7 2295.79 1140.06 2324.79 1102.84C2330.99 1094.89 2337.74 1086.9 2346.61 1083.78C2350.47 1082.33 2406.9 1115.16 2410.83 1115.5C2409.57 1110.61 2385.01 1061.05 2383.93 1056C2357.88 1073.06 2292.28 1109.17 2268.86 1130.66C2239.59 1114.24 2211.8 1094.58 2185.89 1071.98C2187.4 1060.88 2233.28 1069.33 2241.17 1075.64C2282.77 1035.76 2334.22 991.471 2384.28 967.129C2401.64 958.605 2420.92 951.041 2438.97 957.683C2462.64 966.354 2474.66 995.585 2483.95 1021.86C2510.31 1095.77 2550.81 1167.71 2499.75 1239.84Z" fill="#2B303F" />
                        <path id="Vector_105" d="M2696.41 1185.71C2711.81 1187.69 2728.45 1189.59 2740.04 1201.37C2748.79 1210.32 2753.12 1223.53 2756.9 1236.24C2761.44 1251.38 2765.73 1266.61 2769.75 1281.94C2773.31 1295.6 2776.62 1311.06 2770.6 1323.61C2765.51 1334.2 2728.7 1435 2719.35 1429.65C2698.84 1417.89 2570.8 1331.26 2469.34 1265.11C2458.96 1258.34 2460.07 1262.92 2456.01 1250.14C2444.7 1214.28 2486.1 1135.22 2522.15 1136.79C2548.32 1137.94 2577.21 1157.39 2602.47 1165.23C2633.42 1174.88 2665.06 1178.13 2696.41 1185.71Z" fill="white" />
                        <path id="Vector_106" d="M2650.32 1176.1C2622.52 1184.76 2589.94 1198.48 2570.14 1222.33C2563.2 1230.7 2562.15 1265.21 2695.71 1319.85C2699.61 1321.42 2732.32 1307.1 2734.46 1298.85C2736.6 1290.6 2692.52 1266.07 2686.7 1260.62C2684.01 1258.09 2680.63 1256.75 2677.95 1254.26C2675.27 1251.77 2651.42 1247.5 2653.16 1244.06C2655.36 1239.81 2682.79 1240.43 2687.03 1241.47C2707.97 1246.65 2762.02 1276.42 2736.54 1214.16C2722.19 1179.45 2677.75 1167.68 2650.32 1176.1Z" fill="url(#paint3_linear_359_11)" />
                        <path id="Vector_107" d="M2680.07 1351.78C2676.7 1356.17 2674.45 1361.53 2673.58 1367.28C2673.45 1373.55 2674.99 1379.72 2678.01 1384.96C2696.07 1422.08 2730.83 1443.82 2763.78 1463.58C2770.79 1467.76 2810.03 1495.01 2817.89 1495.89C2832.45 1497.59 2853.28 1485.79 2856.58 1447.59C2858.45 1427.81 2852.91 1350.89 2857.62 1331.56C2849.56 1320.98 2836.5 1315.15 2828.05 1325.34C2819.59 1335.53 2820.7 1419.94 2809.54 1424.63C2785.9 1434.54 2781.92 1365.7 2772.67 1309.86C2767.36 1278.07 2694.33 1330.55 2680.07 1351.78Z" fill="white" />
                        <path id="Vector_108" d="M2751.54 1281.69C2746.83 1282.61 2742.29 1284.43 2738.13 1287.1C2736.06 1288.44 2734.32 1290.35 2733.05 1292.65C2731.79 1294.95 2731.05 1297.57 2730.89 1300.28C2730.91 1307.85 2723.22 1320.12 2763.65 1331.09C2767.21 1332.08 2791.38 1312.5 2793.26 1308.87C2796.86 1302.55 2798.37 1294.97 2797.5 1287.5C2793.37 1261.99 2765.12 1278.02 2751.54 1281.69Z" fill="url(#paint4_linear_359_11)" />
                        <path id="Vector_109" d="M2728.76 1302.49L2743.07 1302.2C2743.38 1302.18 2743.69 1302.23 2743.99 1302.34C2744.52 1302.64 2744.95 1303.14 2745.2 1303.76C2748.25 1309.07 2752.56 1313.28 2757.64 1315.89C2762.71 1318.5 2768.34 1319.4 2773.85 1318.48C2769.92 1315.12 2768.18 1309.51 2766.28 1304.35C2764.07 1298.33 2761.45 1292.52 2758.44 1286.98C2757.81 1285.61 2756.9 1284.43 2755.79 1283.54C2754.81 1283.03 2753.74 1282.76 2752.67 1282.73C2751.59 1282.7 2750.51 1282.91 2749.51 1283.36C2743.1 1285.21 2737.13 1288.64 2732 1293.4" fill="url(#paint5_linear_359_11)" />
                        <path id="Vector_110" d="M2658.56 1158.43C2630.78 1167.05 2598.2 1180.77 2578.41 1204.62C2571.46 1213 2545.3 1242.94 2678.85 1297.67C2682.74 1299.26 2685.48 1292.1 2688.08 1288.44C2693.22 1282.12 2697.2 1274.7 2699.82 1266.61C2701.96 1258.36 2700.78 1248.36 2694.96 1242.92C2692.27 1240.38 2688.89 1239.04 2686.21 1236.55C2683.53 1234.07 2659.83 1229.77 2661.55 1226.45C2663.75 1222.19 2691.18 1222.82 2695.41 1223.88C2716.36 1229.03 2770.41 1258.8 2744.92 1196.58C2730.4 1161.71 2685.91 1149.9 2658.56 1158.43Z" fill="white" />
                        <path id="Vector_111" d="M2658.56 1158.43C2630.78 1167.05 2598.2 1180.77 2578.41 1204.62C2571.46 1213 2545.3 1242.94 2678.85 1297.67C2682.74 1299.26 2685.48 1292.1 2688.08 1288.44C2693.22 1282.12 2697.2 1274.7 2699.82 1266.61C2701.96 1258.36 2700.78 1248.36 2694.96 1242.92C2692.27 1240.38 2688.89 1239.04 2686.21 1236.55C2683.53 1234.07 2659.83 1229.77 2661.55 1226.45C2663.75 1222.19 2691.18 1222.82 2695.41 1223.88C2716.36 1229.03 2770.41 1258.8 2744.92 1196.58C2730.4 1161.71 2685.91 1149.9 2658.56 1158.43Z" fill="white" />
                        <path id="Vector_112" d="M2870.3 1273.7C2874.34 1276.78 2874.18 1283.86 2871.91 1288.75C2869.63 1293.64 2865.79 1297.44 2863.36 1302.27C2861.52 1306.44 2860.19 1310.86 2859.4 1315.44C2857.5 1323.9 2854.13 1334.55 2845.26 1335.61C2842.71 1335.73 2840.19 1335.1 2837.93 1333.77C2835.66 1332.45 2833.74 1330.48 2832.34 1328.06C2822.56 1313.42 2830.86 1300.99 2842.94 1293.84C2845.68 1292.37 2870.3 1273.7 2870.3 1273.7Z" fill="url(#paint6_linear_359_11)" />
                        <path id="Vector_113" d="M2761.32 1252.61C2754.76 1263.75 2749.67 1276.6 2750.38 1290.24C2751.09 1303.88 2759 1318.16 2770.95 1322.23C2778.77 1324.86 2786.95 1323 2794.59 1320.75C2820.32 1313.16 2845.93 1300.45 2861.3 1276.74C2866.27 1269.83 2869.18 1261.29 2869.61 1252.36C2869.63 1242.85 2865.68 1233.58 2860.93 1225.38C2856.08 1216.32 2849.59 1208.55 2841.9 1202.58C2834.93 1197.54 2826.71 1195.18 2818.47 1195.85C2811.05 1196.51 2806.53 1201.91 2800.89 1207C2785.98 1220.37 2771.72 1234.81 2761.32 1252.61Z" fill="url(#paint7_linear_359_11)" />
                        <path id="Vector_114" d="M2733.24 1244L2769.02 1224.76C2769.97 1224.24 2771.01 1223.96 2772.07 1223.93C2773.12 1223.89 2774.17 1224.11 2775.15 1224.56C2776.12 1225.01 2777.01 1225.69 2777.75 1226.55C2778.48 1227.41 2779.05 1228.44 2779.42 1229.57L2789.02 1258.95C2789.69 1260.99 2789.67 1263.23 2788.97 1265.25C2788.26 1267.27 2786.93 1268.92 2785.22 1269.88L2715.71 1308.49C2714.72 1309.04 2713.64 1309.33 2712.55 1309.36C2711.45 1309.39 2710.37 1309.15 2709.36 1308.66C2708.35 1308.17 2707.45 1307.44 2706.71 1306.52C2705.97 1305.6 2705.41 1304.51 2705.07 1303.32L2700.07 1285.59C2699.62 1284.02 2699.57 1282.34 2699.93 1280.75C2700.29 1279.15 2701.05 1277.71 2702.1 1276.58L2731.27 1245.47C2731.86 1244.87 2732.52 1244.38 2733.24 1244Z" fill="#3E3E54" />
                        <path id="Vector_115" d="M2729.45 1241.46L2765.24 1222.22C2766.19 1221.7 2767.23 1221.42 2768.28 1221.38C2769.34 1221.35 2770.38 1221.57 2771.36 1222.02C2772.34 1222.47 2773.22 1223.15 2773.96 1224.01C2774.7 1224.87 2775.27 1225.9 2775.64 1227.03L2785.24 1256.41C2785.91 1258.44 2785.89 1260.69 2785.18 1262.71C2784.48 1264.73 2783.14 1266.38 2781.43 1267.34L2711.92 1305.95C2710.94 1306.49 2709.86 1306.79 2708.76 1306.82C2707.67 1306.85 2706.58 1306.61 2705.57 1306.12C2704.57 1305.63 2703.66 1304.9 2702.92 1303.98C2702.18 1303.06 2701.62 1301.97 2701.28 1300.78L2696.28 1283.05C2695.83 1281.48 2695.79 1279.8 2696.15 1278.21C2696.51 1276.61 2697.26 1275.16 2698.32 1274.04L2727.41 1242.87C2728.02 1242.29 2728.71 1241.81 2729.45 1241.46Z" fill="#3E3E54" />
                        <path id="Vector_116" d="M2699.09 1246.58C2706.72 1241.89 2714.6 1237.75 2722.67 1234.16C2725.16 1233.03 2727.84 1232.53 2730.51 1232.68C2731.85 1232.77 2733.14 1233.24 2734.27 1234.04C2735.4 1234.85 2736.33 1235.98 2736.98 1237.31C2737.13 1237.64 2737.19 1238.02 2737.16 1238.4C2737.02 1238.79 2736.78 1239.12 2736.46 1239.34C2734.56 1241.01 2732.44 1242.31 2730.18 1243.2C2728.71 1243.63 2727.29 1244.23 2725.93 1244.99C2725.25 1245.38 2724.68 1245.96 2724.25 1246.67C2723.82 1247.38 2723.55 1248.21 2723.47 1249.07C2723.51 1250.03 2723.72 1250.98 2724.08 1251.86L2727.76 1262.69C2728.71 1265.79 2729.93 1268.76 2731.42 1271.57C2733.24 1274.89 2735.92 1277.94 2736.25 1281.88C2736.8 1288.22 2731.95 1291.1 2728.79 1295.16C2727.52 1297.15 2725.83 1298.74 2723.87 1299.78C2721.9 1300.81 2719.75 1301.26 2717.59 1301.08C2708.82 1301.12 2700.1 1299.45 2691.83 1296.13C2685.75 1293.7 2679.56 1289.99 2676.68 1283.44C2675.56 1281.01 2674.89 1278.34 2674.7 1275.61C2674.52 1272.87 2674.84 1270.11 2675.63 1267.52C2676.66 1265.56 2677.88 1263.74 2679.25 1262.08C2680.28 1259.41 2681.77 1257 2683.65 1255.02C2686.23 1253.11 2689.01 1251.58 2691.92 1250.44C2694.37 1249.14 2696.71 1247.89 2699.09 1246.58Z" fill="url(#paint8_linear_359_11)" />
                        <path id="Vector_117" d="M2805.89 1183.4C2798.44 1186.61 2792.4 1193.07 2789.11 1201.35C2788.77 1202.09 2788.61 1202.91 2788.63 1203.73C2788.92 1204.91 2789.58 1205.93 2790.48 1206.59C2798.93 1214.37 2807.94 1221.31 2817.42 1227.36C2814.58 1229.85 2814.88 1234.94 2816.67 1238.48C2818.45 1242.02 2821.31 1244.63 2823.22 1248.05C2824.5 1250.34 2825.33 1252.91 2825.67 1255.59C2826 1258.28 2825.83 1261.01 2825.17 1263.61C2824.41 1266.53 2823.07 1269.19 2822.32 1272.08C2821.85 1273.49 2821.68 1275 2821.83 1276.5C2821.97 1278 2822.42 1279.44 2823.14 1280.7C2824.11 1282.04 2825.59 1283.15 2825.58 1284.88C2825.48 1285.86 2825.13 1286.77 2824.57 1287.52C2820.89 1293.2 2816.59 1298.33 2811.77 1302.78C2819.16 1302.94 2823.69 1290.6 2831.07 1291.8C2831.83 1294.18 2831.92 1296.77 2831.33 1299.21C2830.74 1301.65 2829.5 1303.82 2827.79 1305.42C2824.27 1308.54 2820.05 1310.45 2815.62 1310.94C2828.32 1308.37 2839.47 1300.07 2850.22 1291.95C2855.6 1287.9 2861.55 1282.69 2861.69 1275.43C2861.53 1273.54 2861.57 1271.63 2861.83 1269.75C2862.33 1268.21 2863.11 1266.81 2864.13 1265.64C2867.93 1260.72 2870.65 1254.84 2872.07 1248.49C2872.86 1243.72 2872.83 1238.81 2871.98 1234.06C2870.57 1225.88 2867.7 1218.13 2863.54 1211.28C2859.39 1204.44 2854.04 1198.64 2847.83 1194.25C2841.71 1189.86 2834.84 1188.23 2828.45 1184.82C2824.97 1182.86 2821.18 1181.71 2817.3 1181.47C2813.42 1181.22 2809.54 1181.88 2805.89 1183.4Z" fill="#08a280" />
                        <path id="Vector_118" d="M2453.31 1236.86C2453.31 1236.86 2694.4 1371.86 2709.15 1384.51C2723.89 1397.16 2747.9 1452.99 2747.9 1452.99L2456.34 1260.46C2454.91 1252.67 2453.9 1244.79 2453.31 1236.86V1236.86Z" fill="url(#paint9_linear_359_11)" />
                        <path id="Vector_119" d="M2320.52 1321.02C2321.95 1318.87 2323.25 1316.62 2324.42 1314.28L2337.6 1288.77C2338.81 1286.48 2339.9 1284.11 2340.85 1281.66C2342.79 1282.97 2344.26 1283.96 2346.92 1285.74L2355.96 1291.82C2364.16 1297.33 2366.35 1304.81 2362.1 1313.02C2357.86 1321.24 2350.48 1323.26 2342.46 1317.88L2335.92 1313.48L2332.82 1319.47C2331.58 1321.84 2330.47 1324.29 2329.49 1326.81L2320.52 1321.02ZM2346.17 1309.75C2346.73 1310.24 2347.39 1310.58 2348.08 1310.75C2348.78 1310.91 2349.5 1310.9 2350.19 1310.7C2350.88 1310.51 2351.53 1310.14 2352.08 1309.63C2352.63 1309.11 2353.07 1308.46 2353.37 1307.72C2353.79 1307.07 2354.07 1306.32 2354.19 1305.53C2354.31 1304.74 2354.27 1303.92 2354.08 1303.15C2353.88 1302.38 2353.54 1301.67 2353.07 1301.07C2352.59 1300.47 2352.01 1300 2351.35 1299.7L2345.18 1295.56L2339.99 1305.6L2346.17 1309.75Z" fill="white" />
                        <path id="Vector_120" d="M2372.92 1356.23C2373.7 1353.38 2374.3 1350.47 2374.69 1347.52C2375.47 1342.52 2375.53 1341.1 2375.05 1339.36C2374.62 1337.88 2373.69 1336.64 2372.46 1335.93L2368.26 1333.11L2364.26 1340.84C2362.99 1343.19 2361.88 1345.64 2360.94 1348.17L2352.05 1342.21C2353.52 1340.03 2354.85 1337.74 2356.02 1335.34L2369.12 1309.99C2370.35 1307.69 2371.43 1305.31 2372.38 1302.85C2374.2 1304.07 2375.72 1305.09 2378.53 1306.98L2389.28 1314.21C2395.98 1318.71 2397.75 1325.22 2394.25 1332C2393.32 1334.45 2391.61 1336.41 2389.46 1337.49C2387.32 1338.57 2384.89 1338.69 2382.67 1337.83C2384.39 1339.99 2384.8 1341.72 2384.38 1345.96C2384.21 1347.86 2384.02 1349.75 2383.19 1356.22C2382.98 1357.86 2382.85 1359.34 2382.62 1362.54L2372.92 1356.23ZM2378.86 1329.37C2379.36 1329.77 2379.92 1330.05 2380.51 1330.18C2381.11 1330.31 2381.72 1330.3 2382.31 1330.13C2382.9 1329.97 2383.45 1329.66 2383.93 1329.23C2384.4 1328.8 2384.79 1328.26 2385.07 1327.64C2386.43 1325.01 2385.83 1322.74 2383.25 1321.01L2376.76 1316.65L2372.42 1325.05L2378.86 1329.37Z" fill="white" />
                        <path id="Vector_121" d="M2428.55 1366.95C2422.13 1379.39 2411.22 1383.17 2401.15 1376.41C2391.09 1369.64 2388.68 1357.17 2395.13 1344.7C2401.57 1332.23 2412.19 1328.66 2422.36 1335.5C2432.53 1342.33 2434.93 1354.6 2428.55 1366.95ZM2419.96 1361.18C2423.91 1353.54 2423.22 1346.86 2418.22 1343.5C2413.23 1340.15 2407.66 1342.7 2403.74 1350.29C2399.83 1357.87 2400.42 1364.81 2405.47 1368.2C2410.51 1371.59 2415.92 1369 2419.91 1361.27L2419.96 1361.18Z" fill="white" />
                        <path id="Vector_122" d="M2457.73 1378.8C2458.53 1376.87 2458.63 1374.67 2458.03 1372.65C2457.43 1370.63 2456.16 1368.96 2454.5 1367.99C2449.45 1364.6 2444.28 1367.22 2440.25 1375.01C2436.22 1382.81 2436.93 1389.38 2442.06 1392.82C2443.09 1393.56 2444.26 1394 2445.47 1394.11C2446.68 1394.22 2447.9 1394 2449.02 1393.46C2450.37 1392.77 2451.53 1391.65 2452.35 1390.25L2460.18 1397.49C2458.1 1400.37 2455.38 1402.55 2452.31 1403.83C2449.9 1404.58 2447.39 1404.73 2444.93 1404.27C2442.48 1403.81 2440.13 1402.75 2438.07 1401.17C2435.77 1399.77 2433.75 1397.87 2432.11 1395.57C2430.47 1393.27 2429.25 1390.62 2428.53 1387.77C2427.42 1381.35 2428.53 1374.69 2431.65 1369.19C2433 1366.28 2434.85 1363.7 2437.09 1361.62C2439.33 1359.53 2441.91 1357.97 2444.69 1357.04C2449.65 1355.89 2454.8 1356.99 2459.06 1360.12C2461.47 1361.5 2463.55 1363.53 2465.11 1366.04C2466.68 1368.54 2467.7 1371.44 2468.07 1374.48C2468.43 1377.24 2468.18 1380.06 2467.37 1382.69L2457.73 1378.8Z" fill="white" />
                        <path id="Vector_123" d="M2483.1 1430.69C2480.99 1428.9 2478.77 1427.26 2476.47 1425.79L2463.46 1417.05C2460.3 1414.93 2459.15 1414.16 2457.28 1413.11C2458.75 1411.01 2460.05 1408.78 2461.19 1406.44L2474.47 1380.72C2475.7 1378.44 2476.77 1376.06 2477.69 1373.6C2479.4 1374.75 2480.56 1375.53 2483.79 1377.7L2496.93 1386.53C2498.97 1387.95 2501.09 1389.22 2503.28 1390.35L2498.72 1399.16C2496.8 1397.53 2494.8 1396.03 2492.72 1394.68L2481.97 1387.46L2478.33 1394.5L2487.27 1400.5C2489.32 1401.93 2491.45 1403.21 2493.64 1404.34L2489.13 1413.06C2487.21 1411.42 2485.19 1409.92 2483.1 1408.57L2474.17 1402.56L2470.08 1410.48L2480.75 1417.64C2482.97 1419.2 2485.27 1420.6 2487.65 1421.84L2483.1 1430.69Z" fill="white" />
                        <path id="Vector_124" d="M2501.64 1425.51C2501.05 1426.8 2500.8 1428.26 2500.93 1429.71C2501.14 1430.88 2501.58 1431.98 2502.22 1432.92C2502.86 1433.87 2503.68 1434.64 2504.62 1435.17C2507.8 1437.31 2510.53 1437.01 2511.9 1434.37C2512.27 1433.71 2512.47 1432.93 2512.46 1432.14C2512.45 1431.34 2512.25 1430.57 2511.87 1429.91C2510.31 1427.69 2508.57 1425.64 2506.67 1423.8C2504.47 1421.88 2502.68 1419.42 2501.43 1416.6C2500.67 1414.75 2500.34 1412.71 2500.45 1410.66C2500.55 1408.62 2501.11 1406.64 2502.05 1404.91C2505.8 1397.65 2513.37 1396.36 2521.52 1401.84C2523.79 1403.06 2525.76 1404.9 2527.28 1407.18C2528.79 1409.46 2529.8 1412.12 2530.22 1414.95C2530.42 1417.33 2530.13 1419.74 2529.38 1421.98L2520.03 1417.19C2521.08 1413.85 2520.31 1411.52 2517.15 1409.4C2514.52 1407.63 2511.99 1408.03 2510.87 1410.18C2510.54 1410.86 2510.4 1411.64 2510.47 1412.41C2510.54 1413.18 2510.82 1413.9 2511.27 1414.49C2512.71 1416.5 2514.35 1418.31 2516.16 1419.88C2518.29 1421.89 2520.08 1424.31 2521.46 1427.03C2522.4 1428.99 2522.85 1431.2 2522.76 1433.42C2522.67 1435.65 2522.05 1437.8 2520.96 1439.65C2520.19 1441.28 2519.15 1442.72 2517.88 1443.87C2516.61 1445.03 2515.16 1445.88 2513.59 1446.37C2509.06 1447.26 2504.41 1446.08 2500.64 1443.08C2497.4 1441.04 2494.65 1438.12 2492.63 1434.59C2491.23 1431.88 2490.74 1428.69 2491.26 1425.6C2491.51 1423.87 2491.97 1422.19 2492.6 1420.6L2501.64 1425.51Z" fill="white" />
                        <path id="Vector_125" d="M2533.23 1446.73C2532.61 1448.01 2532.36 1449.48 2532.52 1450.93C2532.72 1452.1 2533.16 1453.2 2533.8 1454.15C2534.44 1455.1 2535.27 1455.87 2536.21 1456.4C2539.39 1458.53 2542.1 1458.22 2543.46 1455.58C2543.83 1454.91 2544.03 1454.13 2544.02 1453.34C2544.02 1452.55 2543.81 1451.78 2543.43 1451.12C2541.88 1448.89 2540.14 1446.84 2538.23 1445C2536.03 1443.09 2534.24 1440.63 2532.99 1437.81C2532.24 1435.95 2531.9 1433.91 2532.01 1431.87C2532.12 1429.83 2532.67 1427.85 2533.62 1426.11C2537.37 1418.86 2544.94 1417.57 2553.08 1423.04C2555.35 1424.27 2557.32 1426.1 2558.84 1428.38C2560.35 1430.67 2561.36 1433.33 2561.78 1436.15C2561.99 1438.54 2561.71 1440.95 2560.95 1443.18L2551.62 1438.41C2552.67 1435.08 2551.87 1432.72 2548.74 1430.62C2546.11 1428.86 2543.55 1429.23 2542.43 1431.39C2542.1 1432.07 2541.96 1432.84 2542.03 1433.61C2542.1 1434.38 2542.38 1435.11 2542.83 1435.69C2544.27 1437.7 2545.91 1439.51 2547.73 1441.08C2549.85 1443.1 2551.66 1445.53 2553.05 1448.25C2553.98 1450.21 2554.42 1452.42 2554.33 1454.64C2554.23 1456.86 2553.61 1459.01 2552.52 1460.86C2551.76 1462.49 2550.72 1463.93 2549.45 1465.09C2548.18 1466.24 2546.72 1467.09 2545.16 1467.58C2540.64 1468.47 2535.99 1467.3 2532.23 1464.3C2528.98 1462.26 2526.22 1459.34 2524.2 1455.8C2522.79 1453.09 2522.31 1449.89 2522.82 1446.8C2523.09 1445.08 2523.55 1443.41 2524.19 1441.83L2533.23 1446.73Z" fill="white" />
                        <path id="Vector_126" d="M2607.65 1471.41C2603.89 1472.65 2600.33 1474.56 2597.1 1477.08C2590.35 1481.76 2584.48 1487.94 2579.86 1495.25L2570.32 1488.84C2574.44 1483.14 2579.27 1478.14 2584.64 1474C2588.83 1470.8 2593.29 1468.09 2597.96 1465.91L2586.18 1458C2584.12 1456.56 2581.99 1455.27 2579.78 1454.14L2584.43 1445.15C2586.51 1446.9 2588.68 1448.51 2590.93 1449.96L2604.94 1459.37C2608.75 1461.93 2609.91 1462.71 2611.68 1463.66L2607.65 1471.41Z" fill="white" />
                        <path id="Vector_127" d="M2618.52 1486.26C2618.4 1486.5 2618.17 1486.95 2617.62 1487.67C2620.38 1487.41 2623.15 1488.2 2625.48 1489.93C2627.15 1490.95 2628.63 1492.34 2629.81 1494.04C2631 1495.73 2631.87 1497.68 2632.37 1499.76C2632.77 1501.72 2632.82 1503.74 2632.52 1505.72C2632.21 1507.69 2631.56 1509.58 2630.6 1511.26C2625.96 1520.25 2617.9 1522.14 2609.18 1516.28C2606.87 1514.98 2604.87 1513.06 2603.35 1510.68C2601.83 1508.31 2600.85 1505.55 2600.47 1502.64C2600.31 1500.39 2600.53 1498.13 2601.12 1495.97L2610.18 1500.08C2609.68 1501.64 2609.72 1503.35 2610.28 1504.88C2610.85 1506.4 2611.9 1507.63 2613.23 1508.3C2613.94 1508.91 2614.75 1509.32 2615.62 1509.53C2616.48 1509.74 2617.38 1509.72 2618.24 1509.5C2619.1 1509.27 2619.91 1508.83 2620.61 1508.21C2621.31 1507.59 2621.88 1506.81 2622.29 1505.91C2622.94 1504.42 2623.14 1502.73 2622.88 1501.09C2622.62 1499.45 2621.9 1497.95 2620.83 1496.82C2619.75 1495.68 2618.39 1494.97 2616.94 1494.8C2615.48 1494.62 2614.02 1494.99 2612.76 1495.84L2605.52 1489.84C2606.41 1488.54 2607.13 1487.49 2607.94 1486.26L2616.65 1472.28C2617.16 1471.3 2617.88 1470.24 2618.82 1468.49C2620.55 1469.66 2620.97 1469.94 2622.97 1471.28L2635.32 1479.58C2637.56 1481.18 2639.89 1482.59 2642.3 1483.83L2638.04 1492.07C2635.93 1490.32 2633.74 1488.71 2631.48 1487.22L2621.89 1480.77L2618.52 1486.26Z" fill="white" />
                        <path id="Vector_128" d="M2653.99 1517.24C2653.71 1515.53 2653.72 1513.77 2654.03 1512.07C2654.33 1510.36 2654.94 1508.75 2655.8 1507.31C2657.21 1504.14 2659.66 1501.74 2662.61 1500.62C2665.3 1499.99 2668.1 1500.57 2670.41 1502.26C2671.61 1502.99 2672.67 1503.98 2673.54 1505.18C2674.4 1506.37 2675.05 1507.76 2675.44 1509.24C2675.82 1512.72 2675.18 1516.24 2673.62 1519.26C2672.06 1522.28 2669.67 1524.63 2666.81 1525.94C2664.13 1526.5 2661.36 1525.95 2659.01 1524.37C2656.65 1522.79 2654.88 1520.29 2654.02 1517.34L2653.99 1517.24ZM2693.12 1516.88L2694.33 1520.24L2646.8 1542L2645.62 1538.66L2693.12 1516.88ZM2661.45 1511.03C2659.41 1514.98 2659.43 1517.75 2661.54 1519.16C2663.64 1520.57 2665.73 1519.27 2667.79 1515.28C2669.85 1511.3 2669.81 1508.56 2667.73 1507.17C2665.65 1505.77 2663.56 1507.23 2661.56 1511.1L2661.45 1511.03ZM2664.7 1549.55C2664.42 1547.82 2664.43 1546.05 2664.75 1544.33C2665.07 1542.61 2665.68 1540.98 2666.55 1539.53C2667.27 1537.97 2668.25 1536.6 2669.43 1535.48C2670.62 1534.36 2671.98 1533.53 2673.46 1533.02C2674.79 1532.69 2676.17 1532.67 2677.51 1532.95C2678.86 1533.23 2680.14 1533.8 2681.29 1534.65C2686.91 1538.43 2688.08 1544.66 2684.5 1551.59C2683.79 1553.17 2682.81 1554.57 2681.61 1555.71C2680.42 1556.85 2679.03 1557.7 2677.54 1558.21C2676.22 1558.55 2674.85 1558.59 2673.51 1558.31C2672.17 1558.04 2670.9 1557.46 2669.76 1556.62C2667.37 1555.14 2665.58 1552.67 2664.76 1549.71L2664.7 1549.55ZM2672.36 1543.31C2670.25 1547.38 2670.23 1550.16 2672.36 1551.59C2674.49 1553.02 2676.6 1551.68 2678.72 1547.58C2680.84 1543.47 2680.63 1541.07 2678.55 1539.67C2676.48 1538.28 2674.38 1539.66 2672.42 1543.47L2672.36 1543.31Z" fill="white" />
                    </g>
                </g>
                <defs>
                    <linearGradient id="paint0_linear_359_11" x1="1603.33" y1="1342.62" x2="3322.18" y2="1342.62" gradientUnits="userSpaceOnUse">
                        <stop offset="0.21" stopColor="white" stopOpacity="0" />
                        <stop offset="1" stopColor="white" stopOpacity="0.2" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_359_11" x1="2522.15" y1="1468.19" x2="3041.03" y2="1227.63" gradientUnits="userSpaceOnUse">
                        <stop stopOpacity="0" />
                        <stop offset="0.99" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_359_11" x1="2419.09" y1="1492.66" x2="2238.04" y2="1099.07" gradientUnits="userSpaceOnUse">
                        <stop stopOpacity="0" />
                        <stop offset="0.99" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_359_11" x1="2788.56" y1="1189.07" x2="2233.84" y2="1404.53" gradientUnits="userSpaceOnUse">
                        <stop stopOpacity="0" />
                        <stop offset="0.99" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_359_11" x1="2780.92" y1="1295.74" x2="2728.68" y2="1313.65" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#E5C5D5" />
                        <stop offset="0.42" stopColor="#EAD4D1" />
                        <stop offset="1" stopColor="#F0E9CB" />
                    </linearGradient>
                    <linearGradient id="paint5_linear_359_11" x1="2721.75" y1="1335.45" x2="2807.3" y2="1273.22" gradientUnits="userSpaceOnUse">
                        <stop stopOpacity="0" />
                        <stop offset="0.99" />
                    </linearGradient>
                    <linearGradient id="paint6_linear_359_11" x1="2837.43" y1="1292.52" x2="2865.85" y2="1307.2" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#E5C5D5" />
                        <stop offset="0.42" stopColor="#EAD4D1" />
                        <stop offset="1" stopColor="#F0E9CB" />
                    </linearGradient>
                    <linearGradient id="paint7_linear_359_11" x1="2781.68" y1="1240.49" x2="2825.79" y2="1272.28" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#E5C5D5" />
                        <stop offset="0.42" stopColor="#EAD4D1" />
                        <stop offset="1" stopColor="#F0E9CB" />
                    </linearGradient>
                    <linearGradient id="paint8_linear_359_11" x1="2685.93" y1="1247.23" x2="2741.15" y2="1275.75" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#E5C5D5" />
                        <stop offset="0.42" stopColor="#EAD4D1" />
                        <stop offset="1" stopColor="#F0E9CB" />
                    </linearGradient>
                    <linearGradient id="paint9_linear_359_11" x1="2635.66" y1="1274.02" x2="2470.14" y2="1531.52" gradientUnits="userSpaceOnUse">
                        <stop stopOpacity="0" />
                        <stop offset="0.99" />
                    </linearGradient>
                    <clipPath id="clip0_359_11">
                        <rect width="1380.5" height="1108.66" fill="white" transform="matrix(0.830066 0.557665 -0.458985 0.888444 2156.77 389.816)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    )
}

export const AEncapsulateContainer = styled.div`
    display: flex;
    margin: 0px auto;
    justify-content: center;
    align-items: center;
    height: 700px;
    background: ${props => props.theme.bgPrimary };

    @media screen and ${device.tabletmax} {
        height: auto;
        padding: 1rem 0;
    }
   
`
export const AContainer = styled.div`
    padding-top: 80px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
   
   
`
export const ATextTitle = styled.h1`
    margin: 0 auto;
    padding: 0;
    font-family: ${props => props.theme.fontFamily};
    color: ${props => props.theme.textPrimary };
    font-size: 50px;
    font-weight: 900;

    @media screen and ${device.tabletmax} {
        font-size: 42px;
        height: auto;
        word-wrap: normal;
        text-align: center;
        width: 80%;
    }

    @media screen and ${device.mobileLmax} {
        width: 100%;
        text-align: center;
    }
`

export const ATextDescription = styled.p`
    margin-left: 0;
    margin-right: auto;
    padding: 0;
    font-family: ${props => props.theme.fontFamily};
    color: ${props => props.theme.textPrimary };
    font-size: 18px;
    font-weight: 500;
    
    @media screen and ${device.tabletmax} {
        height: auto;
        margin: 0 auto;
        word-wrap: normal;
        text-align: center;
        width: 80%;
    }
`

export const AElement = styled.div`
    height: 100%;
    display: flex;
    margin: 0px auto;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 1rem 0 0;
    align-items: flex-start;

    @media screen and ${device.tabletmax} {
       padding: 0;
       gap: 1rem;
    }
`

export const AButtonStart = styled.a`
    border: 1px solid ${props => props.theme.btnBackground};
    outline: none;
    min-width: min-content;
    max-width: 200px;
    width: 100%;
    height: min-content;
    margin-top: 1rem;
    padding-left: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${props => props.theme.btnBackground };
    color: ${props => props.theme.btnText };
    font-family: ${props => props.theme.fontFamily };
    border-radius: 150px;
    font-size: 1rem;
    text-decoration: none;

    svg {
        margin-left: 30%;
    }

    &:hover {
        transition: 0.12s all ease-in;
        border: 1px solid ${props => props.theme.hoverColor };
        color: ${props => props.theme.hoverColor };
        background: white;
    }

    @media screen and ${device.tabletmax} {
        height: 40px;
        margin: 0 auto;
        width: 200px;
        justify-content: flex-start;
     }
`

export const WorkspaceSVG = styled(WorkspaceSVGBase)`
    width: 450px !important;
    height: auto !important;
    & #background {
        fill:  ${props => props.theme.bgSecondary };
    }

    & #group > #plant {
        animation: floating_plant 2s linear infinite alternate;

        @keyframes floating_plant {
            0% {
                transform: translateY(0px);
            }
            100% {
                transform: translateY(-55px);
            }
        }
    }
    & #group > #loading_bar {
        animation: float 3s linear infinite alternate;

        @keyframes float {
            0% {
                transform-origin: center;
                opacity: 0;
            }
            100% {
                transform-origin: center;
                opacity: 1;
            }
        }
    }

    & #group > #smoke {
        transform-center: bottom center;
        animation: smoke_float 5s linear infinite;

        @keyframes smoke_float {
            0% {
                transform: rotate(0deg);
                fill: white;
                
            }
            25% {
                transform: rotate(0.3deg);
                fill: #ced4cf;
            }
            50% {
                transform: rotate(-0.3deg);
            }
            100% {
                transform: rotate(0deg);
            }
        }
    }

    @media screen and ${device.tabletmax} {
        display: none;
    }

    

    
`