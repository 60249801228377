import { PseudoDivIcons } from "../utils/library"
// Default data
const PSEUDOELEMENTS = [
    {
      icon: PseudoDivIcons.people_box,
      text: "Research user needs!"
    },
    {
      icon: PseudoDivIcons.bullseye,
      text: "State needs & problems!"
    },
    {
      icon: PseudoDivIcons.lightbulb,
      text: "Challenge assumptions & create ideas!",
    },
    {
      icon: PseudoDivIcons.design,
      text: "Start to create solutions!",
      spin: true
    },
    {
      icon: PseudoDivIcons.test,
      text: "Validate solutions!"
    }
  ]

export default PSEUDOELEMENTS;